import ReactDOM from "react-dom"

import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

import "./App.css"

import App from "./App"

import { BrowserRouter } from "react-router-dom"

import { datadogRum } from '@datadog/browser-rum';

const environments = {
  PRODUCTION: "production",
  QA: "qa",
  DEVELOPMENT: "development",
  STAGE: "stage",
};

const backendEnvironment = () => {
  const url = window.location.hostname.split(".")[0];

  switch (url) {
    case "canteiro-obrasqa":
      return environments.QA;
    case "canteiro-obrasstage":
      return environments.STAGE;
    case "canteiro-obras":
      return environments.PRODUCTION;
    default:
      return environments.DEVELOPMENT;
  }
};

datadogRum.init({
  applicationId: '17a987f4-b483-4cfe-9ab5-b9c95234d69a',
  clientToken: 'pubc81422e1fd62b21e2286a1869cef1a03',
  site: 'datadoghq.com',
  service: 'onboarding-canteiro',
  env: backendEnvironment(),
  // Specify a version number to identify the deployed version of your application in Datadog 
  // version: '1.0.0', 
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [
    (url) => url.startsWith("https://apidev.direcional.com.br/onboardingCanteiro/v1/"),
    (url) => url.startsWith("https://apihmg.direcional.com.br/onboardingCanteiro/v1/"),
    (url) => url.startsWith("https://apistage.direcional.com.br/onboardingCanteiro/v1/"),
    (url) => url.startsWith("https://api.direcional.com.br/onboardingCanteiro/v1/")
  ],
});

datadogRum.startSessionReplayRecording();

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
)

serviceWorkerRegistration.register()
