import React, { useEffect } from "react";
import { calculateProgressBar, formatDateBugFix, showFormatedCpf } from "../helpers/format";
import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  cardVacancy: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    padding: 16,
    paddingBottom: 12,
    borderRadius: 8,
    backgroundColor: '#fff',
    boxShadow: "0px 2px 4px -2px rgba(77, 77, 77, 0.06), 0px 4px 8px -2px rgba(77, 77, 77, 0.10)",
    marginBottom: 8
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleVacancy: {
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 700,
    color: '#1d3054'
  },
  itemVacancy: {
    fontSize: 12,
    lineHeight: "18px",
    fontWeight: 400,
    color: '#1d3054',
    marginBottom: 4
  }
}));


const TitleVacancyDetails = ({ vacancyDetails, filledDocuments = 0, totalDocuments = 1, disableProgress }) => {
  const classes = useStyles();

  useEffect(() => {
  }, [vacancyDetails])

  return (
    <div className={classes.cardVacancy}>
      <div className={classes.titleContainer}>
        <Typography variant="body2" className={classes.titleVacancy}>
          {vacancyDetails.nome}
        </Typography>
        {!disableProgress &&
          <Typography variant="body2" className={classes.titleVacancy}>
            {filledDocuments}/{totalDocuments} etapas
          </Typography>}
      </div>
      {!disableProgress && (
        <LinearProgress
          color="primary"
          variant="determinate"
          style={{ borderRadius: 8, height: 8, marginTop: 12, marginBottom: 12 }}
          value={totalDocuments ? calculateProgressBar(filledDocuments, totalDocuments) : 0}
        />
      )}
      <Typography variant="body2" className={classes.itemVacancy}>
        <strong>Nº de chamado:</strong> {vacancyDetails.nrChamado}
      </Typography>
      <Typography variant="body2" className={classes.itemVacancy}>
        <strong>Data de abertura: </strong>{formatDateBugFix(vacancyDetails?.dataAbertura)}
      </Typography>
      <Typography variant="body2" className={classes.itemVacancy}>
        <strong>Empregado:</strong> {vacancyDetails.empregado?.nome || 'N/A'}
      </Typography>
      <Typography variant="body2" className={classes.itemVacancy}>
        <strong>CPF:</strong> {showFormatedCpf(vacancyDetails.empregado?.cpf) || 'N/A'}
      </Typography>
    </div>
  );
}

export default TitleVacancyDetails;
