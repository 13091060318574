import { makeStyles, useTheme } from "@material-ui/core/styles"

import { Grid, Typography } from "@material-ui/core"

export default function VacanciesButton({
  filled,
  onClick,
  divider,
  disabled,
  lastIcon,
  firstIcon,
  buttonText,
  helperText,
  marginBottom,
  numberOfVacancies,
}) {
  const theme = useTheme()

  const background = () => {
      return "#fff"
  }

  const borderColor = () => {
      return "#848484"
  }

  const buttonTextColor = () => {
    if (filled) {
      return "#4D4D4D"
    } else {
      return theme.palette.primary.main
    }
  }

  const useStyles = makeStyles({
    vacanciesButton: {
      height: 48,
      width: "100%",
      borderRadius: 4,
      paddingRight: 12,
      border: "2px solid",
      alignItems: "center",
      background: background(),
      borderColor: borderColor(),
      opacity: disabled ? 0.4 : 1,
      paddingLeft: firstIcon ? 12 : 0,
    },
    buttonText: {
      marginLeft: 16,
      fontWeight: 400,
      color: buttonTextColor(),
    },
    helperText: {
      fontSize: 11,
      color: "#000",
      fontWeight: 700,
      marginBottom: marginBottom,
    },
    numberOfVacancies: {
      fontWeight: 400,
      marginLeft: "auto",
      color: theme.palette.secondary.main,
    },
  })

  const classes = useStyles()

  const handleClick = () => {
    if (disabled) {
      return
    } else {
      return onClick
    }
  }

  return (
    <>
       <Grid container className={classes.vacanciesButton} onClick={handleClick()}>
        {firstIcon}

        <Typography variant='body2' className={classes.buttonText}>
          {buttonText}
        </Typography>

        <Typography variant='body2' className={classes.numberOfVacancies}>
          {numberOfVacancies}
        </Typography>

        {divider ? <hr style={{ marginLeft: 12, marginRight: 12, height: 24 }} /> : null}

        {lastIcon}
      </Grid>

      {helperText ? <Typography className={classes.helperText}>{helperText}</Typography> : null}
    </>
  )
}
