import { useState } from "react"

import { Paper, Typography, Grid, Tooltip } from "@material-ui/core"

import { makeStyles } from "@material-ui/core/styles"

import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa"

import VacanciesButton from "./VacanciesButton"

export default function CostCenterButton({ filteredCostCenter, setFilteredCostCenter }) {
  const useStyles = makeStyles(theme => ({
    paperDropdown: {
      zIndex: 99,
      left: "50%",
      width: "90%",
      position: "absolute",
      transform: "translateX(-50%)",
    },
    dropdownVacancyNumber: {
      marginRight: 24,
      fontWeight: 700,
      textAlign: "right",
      color: theme.palette.secondary.main,
    },
  }))

  const classes = useStyles()

  const [dropdown, setDropdown] = useState(false)

  let costCenters = JSON.parse(localStorage.getItem("costCenters"))

  let selectedCostCenter = JSON.parse(localStorage.getItem("selectedCostCenter"))

  const handleDropdown = () => {
    setDropdown(!dropdown)
  }

  const dropdownLabel = costCenter => {
    let directOrIndirect = ""

    if (costCenter.descricao.includes("INDIRETA")) {
      directOrIndirect = "INDIRETA"
    } else {
      directOrIndirect = "DIRETA"
    }

    const costCenterNumber = costCenter.descricao.split(" ")[1]

    return `OBRA ${costCenterNumber} - ${directOrIndirect}`
  }

  const costCenterFilter = costCenter => {
    setFilteredCostCenter(costCenter)

    // garantindo que não será feita outra chamada à API
    // caso o usuário selecione o mesmo centro de custo
    if (costCenter.descricao === selectedCostCenter?.descricao) {
      setDropdown(false)
      return
    } else {
      localStorage.setItem("selectedCostCenter", JSON.stringify(costCenter))

      setDropdown(false)
    }
  }

  const sumNumberOfVacancies = costCenter => {
    return costCenter.quantidade
  }

  const hideCostCenters = costCenter => {
    if (!window.location.pathname.includes("vagas-em-andamento")) {
      return true
    } else if (
      window.location.pathname.includes("vagas-em-andamento") &&
      costCenter.vagasEmAndamento
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <VacanciesButton
        filled
        onClick={handleDropdown}
        buttonText={
          filteredCostCenter?.descricao
            ? dropdownLabel(filteredCostCenter)
            : "Selecione um centro de custo"
        }
        lastIcon={
          dropdown ? (
            <FaChevronCircleUp size={24} color='#1D3054' />
          ) : (
            <FaChevronCircleDown size={24} color='#1D3054' />
          )
        }
        disabled={costCenters?.length === 0}
      />
      {dropdown ? (
        <Paper className={classes.paperDropdown} elevation={20}>
          {costCenters?.map(costCenter => (
            <Grid
              container
              alignItems='center'
              style={{ marginTop: 4 }}
              key={costCenter.descricao}
              justifyContent='space-between'
              onClick={() => costCenterFilter(costCenter)}
            >
              {hideCostCenters(costCenter) ? (
                <>
                  <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                    <Typography variant='body2' style={{ marginLeft: 24, fontWeight: 700 }}>
                      {dropdownLabel(costCenter)}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <hr style={{ height: 16 }} />
                  </Grid>

                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <Typography variant='body2' className={classes.dropdownVacancyNumber}>
                      {sumNumberOfVacancies(costCenter)} vagas
                    </Typography>
                  </Grid>
                </>
              ) : null}
            </Grid>
          ))}
        </Paper>
      ) : null}
    </>
  )
}
