import { useState } from "react"

export default function useModal() {
  const [modal, setModal] = useState({
    resetQuiz: false,
    userNotFound: false,
    contactBroker: false,
    duplicatedUser: false,
    removeDocument: false,
    removeAllDocument: false,
    devicePermission: false,
    openPrivacyPolicy: false,
    openTermsOfServices: false,
  })

  return { modal, setModal }
}
