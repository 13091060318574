import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { ChevronRight, PersonOutlineOutlined } from '@material-ui/icons';


const DependenteCard = ({ dependente, onEdit }) => {

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onEdit();
    }
  };

  return (
    <div className="card"
      onClick={onEdit}
      role="button"
      tabIndex={0}
      onKeyDown={handleKeyDown}>
      <Grid item className="icon">
        <PersonOutlineOutlined style={{ color: '#1d3054', fontSize: 24, width: 40, height: 40, borderRadius: 4, padding: 8 }} />
      </Grid>
      <div className="cardClickable">
        <div className="textContainer">
          <Typography variant="h6" className='cardTitle'>
            {dependente.nome}
          </Typography>
          <Typography variant="body1" className='cardSubtitle'>
            {dependente.grauParentesco}
          </Typography>
        </div>
        <div className="chevronContainer">
          <ChevronRight style={{ color: '#1d3054' }} />
        </div>
      </div>
    </div>
  );
};

export default DependenteCard;
