import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ErrorInterface from "../../../components/ErrorInterface";
import LoadingInterface from "../../../components/LoadingInterface"
import useDocumentLink from "../../../hooks/useDocumentLink"
import { verifyLink } from "../../../services/api";

function LoadingLink() {
  const { setUser } = useDocumentLink();
  const [error, setError] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const load = async () => {
      const key = sessionStorage.getItem('url-link-key')
      if (key) {
        try {
          const user = await verifyLink(key)
          setUser(user)
          sessionStorage.setItem('nrChamadoLink', user.nrChamado)
          history.push('/link/dados-acesso')
        }
        catch {
          setError(true)
        }
      }
      else {
        setError(true)
      }
    }
    load()
  }, [setUser, setError, history])
  return (
    <>
      {error ? <ErrorInterface /> : <LoadingInterface />}
    </>
  )
}

export default LoadingLink
