import { Tooltip, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import React from 'react';

const CustomTooltip = ({ title, className }) => (
  <Tooltip
    title={
      <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
        {title}
      </Typography>
    }
    arrow
    className={className}
  >
    <InfoOutlined className='tooltipOutlined' />
  </Tooltip>
);

export default CustomTooltip;
