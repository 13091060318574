import { useEffect, useState } from "react"

import { useHistory } from "react-router-dom"

import { Grid, Typography } from "@material-ui/core"

import { makeStyles } from "@material-ui/core/styles"

import { FaChevronCircleRight } from "react-icons/fa"
import { IoPeopleCircleOutline } from "react-icons/io5"

import useAuth from "../../hooks/useAuth"
import useAlert from "../../hooks/useAlert"
import Header from "../../components/Header"
import MainPaper from "../../components/MainPaper"
import useVacancies from "../../hooks/useVacancies"
import BackdropLoading from "../../components/BackdropLoading"
import MobileContainer from "../../components/MobileContainer"
import CostCenterButton from "../../components/CostCenterButton"
import { requestVacanciesByCostCenter } from "../../services/getVacancies"
import VacanciesTypeButton from "../../components/VacanciesTypeButton"

const useStyles = makeStyles(theme => ({
  paperContainer: {
    padding: 16,
    minHeight: "100%",
    justifyContent: "center",
    backgroundColor: "#E8E8E8"
  },
  divider: {
    height: 2,
    width: "70%",
    opacity: 0.4,
    marginTop: 8,
    background: theme.palette.primary.light,
  },
  documentsOnboarding: {
    color: "#000",
    marginLeft: 24,
    fontWeight: 700,
    marginBottom: 8,
  },
}))

export default function Welcome() {
  const { user } = useAuth()

  const classes = useStyles()

  const history = useHistory()

  const { alert, setAlert } = useAlert()

  const { vacancies, setVacancies } = useVacancies()

  const [filteredCostCenter, setFilteredCostCenter] = useState(
    JSON.parse(localStorage.getItem("selectedCostCenter"))
  )

  localStorage.setItem("page", 0)

  useEffect(() => {
    if (vacancies?.obraDescricao === filteredCostCenter?.descricao) return

    setAlert({ ...alert, waitingResponse: true })

    const getVacancies = async () => {
      let vacancies = await requestVacanciesByCostCenter()

      setVacancies(vacancies)

      setAlert({ ...alert, waitingResponse: false })
    }

    getVacancies()
  }, [filteredCostCenter])

  const vacanciesNumberLabel = vacancy => {
    if (vacancy?.length === 0) {
      return 0
    } else if (vacancy?.length > 0) {
      return `+${vacancy.length}`
    } else {
      return ""
    }
  }

  const vacanciesHelperText = (vacancy, helperText) => {
    if (vacancy?.length > 0) {
      return `${helperText}`
    } else {
      return "Nenhuma vaga disponível no centro de custo selecionado."
    }
  }

  return (
    <MobileContainer blueBackground style={{ padding: 0, backgroundColor: "#1C2F54" }}>
      <Header />

      <Grid>
        <Grid style={{ paddingRight: 24, paddingLeft: 24, paddingBottom: 24 }}>
          <Typography variant='h6' style={{ marginTop: 8, color: "#fff", fontSize: 20 }}>
            Boas-vindas,
          </Typography>
          <Typography variant='h4' style={{ color: "#BFD7FF", fontWeight: 700, fontSize: 28 }}>
            {user?.firstName} {user?.lastName}
          </Typography>
          <Typography variant='h6' style={{ color: "#ffffff", fontSize: 14 }}>
            ao onboarding de documentos da Direcional
          </Typography>
        </Grid>

        <MainPaper
          overflow
          position="static"
          transform="none"
        >
          <Grid container className={classes.paperContainer}>

            <Grid style={{
              backgroundColor: "",
            }}>
              <h2 style={{ color: "#1D3054", marginBottom: 8, paddingTop: 16, fontSize: 20, fontWeight: 500 }}>Selecione uma seção/departamento</h2>
              <h4 style={{ color: "#4D4D4D", fontSize: 14, paddingBottom: 8, fontWeight: 400 }}>
                Para acompanhar as vagas que você pode assumir e as assumidas a seleção deve ser feita:
              </h4>
              <CostCenterButton
                filteredCostCenter={filteredCostCenter}
                setFilteredCostCenter={setFilteredCostCenter}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 16, position: "relative" }}>
              <VacanciesTypeButton
                buttonText='Novas vagas'
                disabled={!vacancies?.vagasDirecionadas?.length}
                onClick={() => history.push("/auth/vagas-direcionadas")}
                lastIcon={<FaChevronCircleRight size={24} />}
                firstIcon={<IoPeopleCircleOutline size={24} />}
                numberOfVacancies={vacanciesNumberLabel(vacancies?.vagasDirecionadas)}
                helperText={vacanciesHelperText(
                  vacancies?.vagasDirecionadas,
                  ''
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 16, marginBottom: 16 }}>
              <VacanciesTypeButton
                buttonText='Vagas em andamento'
                helperText=''
                disabled={!vacancies?.vagasEmAndamento?.length}
                onClick={() => history.push("/auth/vagas-em-andamento")}
                lastIcon={<FaChevronCircleRight size={24} />}
                firstIcon={<IoPeopleCircleOutline size={24} />}
                numberOfVacancies={vacanciesNumberLabel(vacancies?.vagasEmAndamento)}
              />
            </Grid>
          </Grid>
        </MainPaper>

        <BackdropLoading open={alert?.waitingResponse} />
      </Grid>
    </MobileContainer>
  )
}
