import { makeStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"
import { useHistory } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";

const GoBackHeader = ({ onGoBack, disabled }) => {
  const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: 16,
      borderBottom: "1px solid #d1d1d1",
      '&:hover': {
        cursor: 'pointer'
      }
    },

    containerEmpty: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: 28,
      borderBottom: "1px solid #d1d1d1",
      '&:hover': {
        cursor: 'pointer'
      }
    },
    icon: {
      color: '#1d3054',
      width: 24,
      height: 24
    },
    text: {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 600,
      color: '#1d3054',
      paddingLeft: 4
    }
  }));

  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      {disabled ? <div
        className={classes.containerEmpty}
      >
        <Typography variant="body2" className={classes.text}>

        </Typography>
      </div> : <div
        className={classes.container}
        onClick={onGoBack ? onGoBack : () => history.goBack()}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (onGoBack) {
              onGoBack();
            } else {
              history.goBack();
            }
          }
        }}
        tabIndex={0}
      >
        <ArrowBack className={classes.icon} />
        <Typography variant="body2" className={classes.text}>
          Voltar
        </Typography>
      </div>}
    </>
  );
};

export default GoBackHeader;
