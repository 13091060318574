import TabList from "@material-ui/lab/TabList"
import { AppBar, Tab } from "@material-ui/core"
import TabPanel from "@material-ui/lab/TabPanel"
import TabContext from "@material-ui/lab/TabContext"

import { makeStyles, useTheme } from "@material-ui/core/styles"

import useTabs from "../hooks/useTabs"

export default function MainTabs({ white, marginTop, tabList, scrollable }) {
  const theme = useTheme()

  const { tab, setTab } = useTabs()

  const useStyles = makeStyles({
    tabTitle: {
      fontSize: 16,
      fontWeight: 700,
      textTransform: "none",
      color: white ? "#FFF" : theme.palette.primary.main,
      "&:disabled": {
        opacity: 1,
        color: white ? "#FFF" : theme.palette.primary.main,
      },
    },
    tabContent: {
      padding: 0,
      marginTop: marginTop ? marginTop : "20%",
    },
    tabLabel: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    tabLabelIcon: {
      marginLeft: 4,
    },
    tabScrollIcon: {
      color: "#000",
    },
  })

  const classes = useStyles()

  const changeTab = (event, newTab) => {
    setTab(newTab)
  }

  return (
    <TabContext value={tab}>
      <AppBar elevation={0} position='static' style={{ backgroundColor: "transparent" }}>
        <TabList
          elevation={0}
          scrollButtons='on'
          onChange={changeTab}
          variant={scrollable ? "scrollable" : "fullWidth"}
          TabScrollButtonProps={{ classes: { root: classes.tabScrollIcon } }}
          TabIndicatorProps={{ style: { background: white ? "#FFF" : theme.palette.primary.main } }}
        >
          {tabList.map(title => (
            <Tab
              key={title.value}
              value={title.value}
              label={title.label}
              disabled={title.disabled}
              className={classes.tabTitle}
            />
          ))}
        </TabList>
      </AppBar>

      {tabList.map(content => (
        <TabPanel key={content.value} value={content.value} className={classes.tabContent}>
          {content.component}
        </TabPanel>
      ))}
    </TabContext>
  )
}
