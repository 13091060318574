import React, { useState, useContext, useEffect } from 'react';
import { FichaContext } from '../../../../context/FichaContext';
import './Componentes.css';
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, Typography } from '@material-ui/core';

const PessoaComDeficiencia = ({ onSave, onExit, setLoading, isLoading }) => {
  const fichaContext = useContext(FichaContext);

  const initialFormData = {
    deficiencia: fichaContext?.ficha.deficiencia || 'Não',
    tpDeficienciaFisica: fichaContext?.ficha.tpDeficienciaFisica || '',
    tpDeficienciaAuditiva: fichaContext?.ficha.tpDeficienciaAuditiva || '',
    tpDeficienciaFala: fichaContext?.ficha.tpDeficienciaFala || '',
    tpDeficienciaVisual: fichaContext?.ficha.tpDeficienciaVisual || '',
    tpDeficienciaMental: fichaContext?.ficha.tpDeficienciaMental || '',
    tpDeficienciaReabilitado: fichaContext?.ficha.tpDeficienciaReabilitado || '',
    tpDeficienciaIntelectual: fichaContext?.ficha.tpDeficienciaIntelectual || '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const isDeficienciaValid =
      formData.deficiencia === 'Não' ||
      (formData.deficiencia === 'Sim' &&
        (Boolean(formData.tpDeficienciaFisica) ||
          Boolean(formData.tpDeficienciaAuditiva) ||
          Boolean(formData.tpDeficienciaFala) ||
          Boolean(formData.tpDeficienciaVisual) ||
          Boolean(formData.tpDeficienciaMental) ||
          Boolean(formData.tpDeficienciaReabilitado) ||
          Boolean(formData.tpDeficienciaIntelectual)));
    setIsFormValid(isDeficienciaValid);
  }, [formData]);

  if (!fichaContext) {
    return null;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
      ...(
        name === 'deficiencia' && value === 'Não'
          ? {
            tpDeficienciaFisica: '',
            tpDeficienciaAuditiva: '',
            tpDeficienciaFala: '',
            tpDeficienciaVisual: '',
            tpDeficienciaMental: '',
            tpDeficienciaReabilitado: '',
            tpDeficienciaIntelectual: '',
          }
          : {}
      ),
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: checked ? 'true' : '',
    }));
  };

  const handleSave = () => {
    onSave('pessoaComDeficiencia', formData);
  };

  const handleExit = () => {
    onExit('onExit', formData);
  };

  return (
    <>
      <Grid style={{ marginRight: 16, marginLeft: 16, marginBottom: 16 }}>
        <Typography variant="h6" className="titleComponente">
          Pessoa com deficiência (PcD)
        </Typography>
        <Typography variant="body2" className="descriptionComponente">
          Etapa obrigatória <span className='asterisk'>*</span>
        </Typography>
      </Grid>

      <form className="formComponente">
        <Grid className='gridComponenteRadio'>
          <Typography variant="h6" className='titleTypography'>
            Pessoa com deficiência (PcD)?<span className='asterisk'>*</span>
          </Typography>
          <FormControl className='formControl' component="fieldset" required>
            <RadioGroup
              aria-label="Pessoa com deficiência?"
              name="deficiencia"
              value={formData.deficiencia}
              onChange={handleChange}
              row
            >
              <FormControlLabel value="Sim" control={<Radio />} label="Sim" />
              <FormControlLabel value="Não" control={<Radio />} label="Não" />
            </RadioGroup>
          </FormControl>
        </Grid>
        {formData.deficiencia === 'Sim' && (
          <Grid className='gridComponente'>
            <FormControl className='formControl' component="fieldset" required>
              <Typography variant="h6" className='titleTypography'>
                Tipo de deficiência <span className='asterisk'>*</span>
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.tpDeficienciaFisica === 'true'}
                      onChange={handleCheckboxChange}
                      name="tpDeficienciaFisica"
                    />
                  }
                  label="Física"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.tpDeficienciaVisual === 'true'}
                      onChange={handleCheckboxChange}
                      name="tpDeficienciaVisual"
                      className='checkbox'
                    />
                  }
                  label="Visual"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.tpDeficienciaAuditiva === 'true'}
                      onChange={handleCheckboxChange}
                      name="tpDeficienciaAuditiva"
                    />
                  }
                  label="Auditiva"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.tpDeficienciaFala === 'true'}
                      onChange={handleCheckboxChange}
                      name="tpDeficienciaFala"
                    />
                  }
                  label="Fala"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.tpDeficienciaIntelectual === 'true'}
                      onChange={handleCheckboxChange}
                      name="tpDeficienciaIntelectual"
                    />
                  }
                  label="Intelectual"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.tpDeficienciaMental === 'true'}
                      onChange={handleCheckboxChange}
                      name="tpDeficienciaMental"
                    />
                  }
                  label="Mental"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.tpDeficienciaReabilitado === 'true'}
                      onChange={handleCheckboxChange}
                      name="tpDeficienciaReabilitado"
                    />
                  }
                  label="Reabilitado"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        )}
      </form>
      <div className="divButtons">
        <Button className="buttonSaveNext" variant="contained" onClick={handleSave} disabled={!isFormValid || isLoading}>
          Próxima Etapa
        </Button>
        <Button className="buttonSaveExit" variant="contained" onClick={handleExit} disabled={!isFormValid || isLoading}>
          Salvar e Continuar Depois
        </Button>
      </div>
    </>
  );
};

export default PessoaComDeficiencia;
