import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { GetVacancyDetails } from '../../../services/api';
import { swalAlert } from '../../../helpers/swalAlert';

export const useFetchVacancyDetail = (nrChamado, setVacancyDetails, vacancyDetails, setLoading, loading, isLinkRoute) => {
  const history = useHistory();

  useEffect(() => {
    /* const fetchVacancyDetail = async () => {
      let response;
      if (!nrChamado) {
        swalAlert("error", "Vaga não encontrada, entre novamente pelo Link enviado para você", "Ok");
        history.push("/link/carregar/");
        return;
      }
      response = await GetVacancyDetails(nrChamado);
      if (response) {
        setVacancyDetails(response.data);
      } else {
        swalAlert("error", "Erro ao buscar detalhes da vaga, tente novamente", "Tentar novamente");
        const targetRoute = '/link/';
        setTimeout(() => history.push(targetRoute), 2000);
      }
      setLoading(false);
    }; */

    const fetchVacancyDetail = async () => {
      let response;
      if (isLinkRoute) {
        if (!nrChamado) {
          swalAlert("error", "Vaga não encontrada, entre novamente pelo Link enviado para você", "Ok");
          history.push("/link/carregar/")
          return;
        }
        response = await GetVacancyDetails(nrChamado);
      } else {
        if (!localStorage.getItem("selectedVacancy")) {
          swalAlert("error", "Vaga não encontrada", "Ok");
          history.push("/auth/vagas-em-andamento")
          return;
        }
        let vacancyNumber = JSON.parse(localStorage.getItem("selectedVacancy")).nrChamado
        response = await GetVacancyDetails(vacancyNumber);
      }
      if (response) {
        setVacancyDetails(response.data);
      } else {
        swalAlert("error", response, "Tentar novamente");
        const targetRoute = isLinkRoute ? '/link/' : '/auth/';
        setTimeout(() => history.push(targetRoute), 2000);
      }
      setLoading(false);
    }

    fetchVacancyDetail();
  }, [nrChamado]);

  return { vacancyDetails, loading };
};
