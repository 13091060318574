export const clearLocalStorage = () => {
  localStorage.removeItem("sectionCenters")
  localStorage.removeItem("selectedSection")
  localStorage.removeItem("selectedVacancy")
  localStorage.removeItem("vacanciesBySection")
  localStorage.removeItem("vacanciesByCostCenter")
  localStorage.removeItem("costCenters")
  localStorage.removeItem("vacancyType")
  localStorage.removeItem("selectedCostCenter")
  localStorage.removeItem("email")
  localStorage.removeItem("externalId")
  localStorage.removeItem("firstName")
  localStorage.removeItem("lastName")
  localStorage.removeItem("isTermsSigned")
  localStorage.removeItem("documentToUpload")
}
