import { makeStyles } from "@material-ui/styles"

import Swal from "sweetalert2"
import Compressor from "compressorjs"

import useAuth from "../hooks/useAuth"
import { dataToBlob } from "../helpers/format"

export function AccessDevice({ id, camera, setFile, outlined, buttonText, buttonIcon, link }) {
  const { images, setImages } = useAuth()

  const useStyles = makeStyles(theme => ({
    button: {
      height: 56,
      width: "100%",
      fontSize: 18,
      fontWeight: 700,
      borderRadius: 8,
      paddingTop: 16,
      paddingBottom: 16,
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
      textTransform: "none",
      justifyContent: "center",
      border: "none",
      color: "#003DA5",
      borderColor: "#FFF",
      background: "#FFF",
    },
    linkButton: {
      height: 56,
      width: "100%",
      fontSize: 18,
      fontWeight: 700,
      borderRadius: 8,
      paddingTop: 16,
      paddingBottom: 16,
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
      textTransform: "none",
      justifyContent: "center",
      border: "none",
      color: "#003DA5",
      borderColor: "#FFF",
      background: "#FFF",
    }
  }))

  const classes = useStyles()

  const verifyValidExtension = file => {
    if (
      file.includes("image/jpeg") ||
      file.includes("image/jpg") ||
      file.includes("image/png") ||
      file.includes("application/pdf")
    ) {
      return true
    } else {
      Swal.fire({
        allowOutsideClick: false,
        title:
          "<h5 style='color: #A93335; margin-top: 8px; margin-bottom: 0px'>Formato inválido</h5>",
        confirmButtonColor: "#29333C",
        confirmButtonText: "        TENTAR NOVAMENTE        ",
        html: "<strong>O documento precisa estar no formato jpg, png ou pdf.</strong> <p>Por favor, tente novamente.</p> <hr/>",
      })
    }
  }

  const verifyFileSize = file => {
    let fileSizeInMb = file.size / 1024 / 1024

    if (fileSizeInMb <= 20) {
      return true
    } else {
      Swal.fire({
        allowOutsideClick: false,
        title:
          "<h5 style='color: #A93335; margin-top: 8px; margin-bottom: 0px'>Tamanho inválido</h5>",
        confirmButtonColor: "#29333C",
        confirmButtonText: "        TENTAR NOVAMENTE        ",
        html: "<strong>O documento tem mais de 20mb e ultrapassa nosso limite de envio.</strong> <p>Por favor, tente novamente.</p> <hr/>",
      })
    }
  }

  const insertImageIntoArray = (event, image) => {
    if (event.target?.id === "tryAgain") {
      images?.imagesArray?.pop()
      setImages({ imagesArray: images?.imagesArray })
    }

    images?.imagesArray?.push(image)

    setImages({ imagesArray: images?.imagesArray })
  }

  const compressImage = (image, event) => {
    if (image?.includes("application/pdf")) {
      if (verifyFileSize(dataToBlob(image))) {
        setFile(image)
        insertImageIntoArray(event, image)
      }
    } else {
      new Compressor(dataToBlob(image), {
        quality: 0.8,
        success: compressedResult => {
          if (verifyFileSize(compressedResult)) {
            setFile(image)
            insertImageIntoArray(event, compressedResult)
          }
        },
      })
    }
  }

  const handleFile = event => {
    const reader = new FileReader()

    reader.onload = async () => {
      if (reader.readyState === 2) {
        if (verifyValidExtension(reader.result)) {
          compressImage(reader.result, event)
        }
      }
    }

    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0])
    }
  }

  return (
    <>
      <label htmlFor={id} className={`${classes.button} ${link ? classes.linkButton : ""}`}>
        <input
          id={id}
          type='file'
          onChange={handleFile}
          style={{ display: "none" }}
          accept='.jpg, .jpeg, .png, .pdf'
          capture={camera ? "environment" : false}
        />

        {buttonIcon}

        <p>{buttonText}</p>
      </label>
    </>
  )
}

export const share = url => {
  if (navigator.share) {
    navigator
      .share({
        text: `${url}`,
        title: "Direcional Engenharia",
      })
      .then(() => console.log("Compartilhamento ok"))
      .catch(error => console.log("Erro: ", error))
  }
}

export const contactWhatsApp = message => {
  window.open(`https://api.whatsapp.com/send?phone=&text=${message}`)
}
