import React, { useState, useContext, useEffect } from 'react';
import { FichaContext } from '../../../../context/FichaContext';
import './Componentes.css';
import { formatDateToBrazilianPattern } from '../../../../helpers/format';
import { verificaIdentidade } from '../../../../helpers/validationFicha';
import { Button, FormControl, Grid, MenuItem, Select, TextField, Tooltip, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

const DocumentoDeIdentidade = ({ onSave, onExit, setLoading, isLoading }) => {
  const fichaContext = useContext(FichaContext);

  const [formData, setFormData] = useState({
    numeroIdentidade: fichaContext?.ficha.numeroIdentidade || '',
    dataEmissaoIdentidade: fichaContext?.ficha.dataEmissaoIdentidade || '',
    estadoEmissorIdentidade: fichaContext?.ficha.estadoEmissorIdentidade || '',
    orgaoEmissorIdentidade: fichaContext?.ficha.orgaoEmissorIdentidade || '',
  });

  useEffect(() => {
    if (!fichaContext) return;
    const { ficha } = fichaContext;

    const formatDateForInput = (date) => {
      if (!date) return '';
      const [day, month, year] = date.split('/');
      return `${year}-${month}-${day}`;
    };

    setFormData((prevState) => ({
      ...prevState,
      dataEmissaoIdentidade: formatDateForInput(ficha.dataEmissaoIdentidade || ''),
    }));
  }, [fichaContext]);

  if (!fichaContext) {
    return null;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const isDateInBrazilianFormat = (date) => {
    return /^\d{2}\/\d{2}\/\d{4}$/.test(date);
  };

  const sanitizeIdentidade = (identidade) => {
    return identidade.replace(/[^a-zA-Z0-9]/g, '');
  };

  const handleSave = () => {
    const dadosParaSalvar = {
      ...formData,
      numeroIdentidade: sanitizeIdentidade(formData.numeroIdentidade),
      dataEmissaoIdentidade: formData.dataEmissaoIdentidade && !isDateInBrazilianFormat(formData.dataEmissaoIdentidade) ? formatDateToBrazilianPattern(formData.dataEmissaoIdentidade) : formData.dataEmissaoIdentidade,
    };
    onSave('documentoDeIdentidade', dadosParaSalvar);
  };

  const handleExit = () => {
    const dadosParaSair = {
      ...formData,
      numeroIdentidade: sanitizeIdentidade(formData.numeroIdentidade),
      dataEmissaoIdentidade: formData.dataEmissaoIdentidade && !isDateInBrazilianFormat(formData.dataEmissaoIdentidade) ? formatDateToBrazilianPattern(formData.dataEmissaoIdentidade) : formData.dataEmissaoIdentidade,
    };
    onExit('onExit', dadosParaSair);
  };

  const isDocumentoDeIdentidadeDisabled = () => {
    const { nacionalidade } = fichaContext.ficha;
    return !verificaIdentidade(nacionalidade);
  };

  const estadosBrasil = [
    'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG',
    'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'
  ];

  const areFieldsFilled = () => {
    const { numeroIdentidade, dataEmissaoIdentidade, estadoEmissorIdentidade, orgaoEmissorIdentidade } = formData;
    return numeroIdentidade !== '' && dataEmissaoIdentidade !== '' && estadoEmissorIdentidade !== '' && orgaoEmissorIdentidade !== '';
  };

  return (
    <>
      <Grid style={{ marginRight: 16, marginLeft: 16, marginBottom: 16 }}>
        <Typography variant="h6" className='titleComponente'>
          Documento de Identidade
        </Typography>
        <Typography variant="body2" className='descriptionComponente'>
          {!isDocumentoDeIdentidadeDisabled() ? (
            <>
              Preenchimento obrigatório <span className="asterisk">*</span>
            </>
          ) : (
            <span className="asterisk">Candidato dispensado dessa etapa, pode salvar e concluir.</span>
          )}
        </Typography>
      </Grid>
      <form className='formComponente'>
        <Grid className='gridComponente'>
          <Typography variant="h6" className='titleTooltip'>
            <span>Número da Identidade <span className='asterisk'>*</span></span>
            <Tooltip style={{ cursor: 'pointer' }} title="Em caso de novo modelo de RG, digitar o CPF" arrow>
              <InfoOutlined className="infoOutlined" />
            </Tooltip>
          </Typography>
          <TextField
            name="numeroIdentidade"
            value={formData.numeroIdentidade}
            onChange={handleChange}
            label=""
            size='small'
            required
            className={!isDocumentoDeIdentidadeDisabled() ? 'textField' : 'textFieldDisabled'}
            disabled={isDocumentoDeIdentidadeDisabled()}
            inputProps={{ maxLength: 40 }}
          />
        </Grid>

        <Grid className='gridComponente'>
          <Typography variant="h6" className='titleTypography'>
            Data de Emissão <span className='asterisk'>*</span>
          </Typography>
          <TextField
            name="dataEmissaoIdentidade"
            value={formData.dataEmissaoIdentidade}
            onChange={handleChange}
            type="date"
            label=""
            inputProps={{ maxLength: 10 }}
            size='small'
            required
            className={!isDocumentoDeIdentidadeDisabled() ? 'textField' : 'textFieldDisabled'}
            disabled={isDocumentoDeIdentidadeDisabled()}
          />
        </Grid>

        <Grid className='gridComponente'>
          <Typography variant="h6" className='titleTypography'>
            Estado emissor <span className='asterisk'>*</span>
          </Typography>
          <FormControl className='formControl' fullWidth>
            <Select
              labelId="estadoEmissorIdentidade-label"
              id="estadoEmissorIdentidade"
              name="estadoEmissorIdentidade"
              value={formData.estadoEmissorIdentidade}
              onChange={(e) => handleChange(e)}
              placeholder='Selecione...'
              label=""
              size='small'
              required
              displayEmpty
              className={!isDocumentoDeIdentidadeDisabled() ? 'selectField' : 'textFieldDisabled'}
              disabled={isDocumentoDeIdentidadeDisabled()}
            >
              <MenuItem value="" disabled>
                Selecione...
              </MenuItem>
              {estadosBrasil.map((estado) => (
                <MenuItem key={estado} value={estado}>
                  {estado}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid className='gridComponente'>
          <Typography variant="h6" className='titleTypography'>
            Órgão Emissor <span className='asterisk'>*</span>
          </Typography>
          <TextField
            name="orgaoEmissorIdentidade"
            value={formData.orgaoEmissorIdentidade}
            onChange={handleChange}
            label=""
            size='small'
            inputProps={{ maxLength: 50 }}
            required
            className={!isDocumentoDeIdentidadeDisabled() ? 'textField' : 'textFieldDisabled'}
            disabled={isDocumentoDeIdentidadeDisabled()}
          />
        </Grid>
      </form>
      <div className='divButtons'>
        <Button
          className='buttonSaveNext'
          variant="contained"
          onClick={handleSave}
          disabled={(!areFieldsFilled() && !isDocumentoDeIdentidadeDisabled()) || isLoading}
        >
          Próxima Etapa
        </Button>
        <Button className='buttonSaveExit' variant="contained" onClick={handleExit} disabled={(!areFieldsFilled() && !isDocumentoDeIdentidadeDisabled()) || isLoading}>
          Salvar e Continuar Depois
        </Button>
      </div>
    </>
  );
};

export default DocumentoDeIdentidade;
