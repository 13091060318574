import { TextField, InputAdornment } from "@material-ui/core"

import SearchIcon from "@material-ui/icons/Search"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"

import { makeStyles } from "@material-ui/styles"

export default function SearchButton({ onChange, value, clearInput }) {
  const useStyles = makeStyles(theme => ({
    searchButton: {
      fontWeight: 700,
      borderRadius: 4,
      background: "#FFF",
    },
  }))

  const classes = useStyles()

  const inputIcon = () => {
    if (value) {
      return (
        <InputAdornment position='start'>
          <HighlightOffIcon onClick={clearInput} style={{ cursor: "pointer" }} />
        </InputAdornment>
      )
    } else {
      return (
        <InputAdornment position='start'>
          <SearchIcon />
        </InputAdornment>
      )
    }
  }

  return (
    <TextField
      fullWidth
      value={value}
      variant='outlined'
      autoComplete='off'
      onChange={onChange}
      placeholder='Pesquisar...'
      className={classes.searchButton}
      InputProps={{
        startAdornment: inputIcon(),

        style: { fontWeight: 700 },
      }}
    />
  )
}
