// import { useState } from "react"

// export default function useAlert() {
//   const [alert, setAlert] = useState({
//     open: false,
//     message: "",
//     severity: "",
//     waitingResponse: false,
//   })

//   return { alert, setAlert }
// }

import { useContext } from "react"
import { AlertContext } from "../context/AlertContext"

export default function useTabs() {
  return useContext(AlertContext)
}
