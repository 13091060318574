import React, { useState, useContext, useEffect } from 'react';
import { FichaContext } from '../../../../context/FichaContext';
import './Componentes.css';
import { verificaReservista } from '../../../../helpers/validationFicha';
import CustomTooltip from './CustomTooltip';
import { Button, FormControl, Grid, MenuItem, Select, TextField, Typography } from '@material-ui/core';

const CertificadoReservista = ({ onSave, onExit, setLoading, isLoading }) => {
  const fichaContext = useContext(FichaContext);

  const [formData, setFormData] = useState({
    numeroCertificadoReservista: fichaContext?.ficha.numeroCertificadoReservista || '',
    situacaoMilitar: fichaContext?.ficha.situacaoMilitar || '',
  });

  useEffect(() => {
    if (fichaContext) {
      const { ficha } = fichaContext;
      setFormData({
        numeroCertificadoReservista: ficha.numeroCertificadoReservista || '',
        situacaoMilitar: ficha.situacaoMilitar || '',
      });
    }
  }, [fichaContext]);

  if (!fichaContext) {
    return null;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleExit = () => {
    onExit('onExit', formData);
  };

  const areFieldsFilled = () => {
    const { numeroCertificadoReservista, situacaoMilitar } = formData;
    return numeroCertificadoReservista !== '' && situacaoMilitar !== '';
  };

  const isCertificadoReservistaRequired = () => {
    const { sexoColaborador, dtNascimento, nacionalidade } = fichaContext.ficha;
    return verificaReservista(sexoColaborador, dtNascimento, nacionalidade);
  };

  return (
    <>
      <Grid style={{ marginRight: 16, marginLeft: 16, marginBottom: 16 }}>
        <Typography variant="h6" className='titleComponente'>
          Certificado de reservista
        </Typography>
        <Typography variant="body2" className='descriptionComponente'>
          {isCertificadoReservistaRequired() ? (
            <>
              Preenchimento obrigatório <span className="asterisk">*</span>
            </>
          ) : (
            <span className="asterisk">Candidato dispensado dessa etapa. Gentileza salvar e concluir.</span>
          )}
        </Typography>
      </Grid>
      <form className='formComponente'>
        <Grid className='gridComponente'>
          <Typography variant="h6" className='titleTooltip'>
            <span>Número do RA <span className='asterisk'>*</span></span>
            <CustomTooltip
              title={`O número RA (Registro de Alistamento) pode ser encontrado no certificado de reservista.\n 1 - No certificado de reservista, procure a seção intitulada 'Dados do Alistamento'.\n 2 - O número RA geralmente está listado como 'Registro de Alistamento' ou apenas 'RA'.\n 3 - Ele pode ser encontrado próximo ao nome e à data de nascimento do reservista.\n 4 - O número RA é uma sequência numérica específica que identifica o alistamento militar do indivíduo.`}
            />
          </Typography>
          <TextField
            name="numeroCertificadoReservista"
            value={formData.numeroCertificadoReservista}
            onChange={handleChange}
            label=""
            inputProps={{ maxLength: 60 }}
            size='small'
            required={isCertificadoReservistaRequired()}
            className={isCertificadoReservistaRequired() ? 'selectField' : 'selectFieldDisabled'}
            disabled={!isCertificadoReservistaRequired()}
          />
        </Grid>

        <Grid className='gridComponente'>
          <Typography variant="h6" className='titleTypography'>
            Situação militar <span className='asterisk'>*</span>
          </Typography>
          <FormControl className='formControl' fullWidth>
            <Select
              id="situacaoMilitar"
              name="situacaoMilitar"
              value={formData.situacaoMilitar}
              onChange={(e) => handleChange(e)}
              size='small'
              label=""
              className={isCertificadoReservistaRequired() ? 'selectField' : 'selectFieldDisabled'}
              required={isCertificadoReservistaRequired()}
              disabled={!isCertificadoReservistaRequired()}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Selecione...
              </MenuItem>
              <MenuItem value="Reservista">Reservista</MenuItem>
              <MenuItem value="Dispensado">Dispensado</MenuItem>
              <MenuItem value="Alistado">Alistado</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </form>
      <div className='divButtons'>
        <Button
          className='buttonSaveExit'
          variant="contained"
          onClick={handleExit}
          disabled={(!areFieldsFilled() && isCertificadoReservistaRequired()) || isLoading}
        >
          Salvar e Concluir
        </Button>
      </div>
    </>
  );
};

export default CertificadoReservista;
