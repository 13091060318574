import { createContext, useState, ReactNode } from "react"

type User = {
  email: string
  nome: string
  telefone: string,
  nrChamado: string,
  dataExpiracao: string
}
type TokenSource = 'email' | 'sms'

type DocumentLinkTypes = {
  user: User,
  setUser: (u: User) => void | User,

  tokenSource: TokenSource
  setTokenSource: (s: TokenSource) => void | TokenSource,

  session: string | null,
  setSession: (s: string | null) => void | (string | null)
}

type DocumentLinkProps = {
  children: ReactNode
}
export const DocumentLinkContext = createContext({} as DocumentLinkTypes)

const DocumentLinkProvider = (props: DocumentLinkProps) => {

  const [user, setUser] = useState<User>({
    email: "",
    nome: "",
    telefone: "",
    nrChamado: "",
    dataExpiracao: ""
  })
  const [tokenSource, setTokenSource] = useState<TokenSource>('email')
  const [session, setSession] = useState<string | null>(null)

  return (
    <DocumentLinkContext.Provider
      value={{
        user,
        setUser,
        tokenSource,
        setTokenSource,
        session,
        setSession
      }}
    >
      {props.children}
    </DocumentLinkContext.Provider>
  )
}

export default DocumentLinkProvider
