// checkFieldsFilled.tsx

const allFieldsFilled = (...args) => {
  for (let i = 0; i < args.length; i++) {
    if (args[i] === null) {
      return false;
    }
  }
  return true;
};

export const checkDadosPessoais = (dados) => {
  if (dados.dtNascimento === "" || dados.cpf === "" || dados.nacionalidade === "" || dados.nome === "" || (dados.naturalidade === "" && dados.estadoNatal === "" && dados.codeTD === "" && (dados.registroNacionalEstrangeiros === "" || dados.dataValidadeRegistroNacionalEstrangeiros === ""))) {
    return false;
  }
  return allFieldsFilled(
    dados.nome,
    dados.dtNascimento,
    dados.cpf,
    dados.nacionalidade,
    dados.naturalidade,
    dados.estadoNatal,
    dados.codeTD,
    dados.registroNacionalEstrangeiros,
    dados.dataValidadeRegistroNacionalEstrangeiros
  );
};

export const checkCarteiraDeTrabalho = (dados) => {
  return allFieldsFilled(
    dados.numeroCtps,
    dados.numeroSerieCtps,
    dados.numeroPisPasep
  );
};

export const checkCertificadoReservista = (dados) => {
  return allFieldsFilled(
    dados.numeroCertificadoReservista,
    dados.situacaoMilitar
  );
};

export const checkDadosBancarios = (dados) => {
  return allFieldsFilled(
    dados.possuiConta,
    dados.bancoPagamento,
    dados.agenciaPagamento,
    dados.numeroConta,
    dados.operacaoBancaria
  );
};

export const checkDocumentoDeIdentidade = (dados) => {
  return allFieldsFilled(
    dados.numeroIdentidade,
    dados.dataEmissaoIdentidade,
    dados.estadoEmissorIdentidade,
    dados.orgaoEmissorIdentidade
  );
};

export const checkEndereco = (dados) => {
  return allFieldsFilled(
    dados.cep,
    dados.tipoRUA,
    dados.rua,
    dados.numero,
    dados.complemento,
    dados.bairro,
    dados.estado,
    dados.municipio,
    dados.pais,
    dados.tipoBairro
  );
};

export const checkEstadoCivil = (dados) => {
  if (dados.estadoCivil !== "S" && dados.estadoCivil !== "C" && dados.estadoCivil !== "I" && dados.estadoCivil !== "P" && dados.estadoCivil !== "V" && dados.estadoCivil !== "O") {
    return false;
  }
  return allFieldsFilled(dados.estadoCivil);
};

export const checkEtnia = (dados) => {
  if (dados.etnia !== 0 && dados.etnia !== 2 && dados.etnia !== 4 && dados.etnia !== 6 && dados.etnia !== 8 && dados.etnia !== 10) {
    return false;
  }
  return allFieldsFilled(dados.etnia);
};

export const checkGeneroOrientacao = (dados) => {
  if (dados.identidadeGenero === 0 || dados.orientacaoSexual === 0 || (dados.sexoColaborador !== "M" && dados.sexoColaborador !== "F")) {
    return false;
  }
  return allFieldsFilled(
    dados.identidadeGenero,
    dados.orientacaoSexual,
    dados.sexoColaborador
  );
};

export const checkGrauInstrucao = (dados) => {
  if (dados.grauInstrucao < 1 && dados.grauInstrucao > 18) {
    return false;
  }
  return allFieldsFilled(dados.grauInstrucao);
};

export const checkInformacoesContato = (dados) => {
  return allFieldsFilled(
    dados.email,
    dados.telefone,
    dados.telefoneEmergencia,
    dados.nomeContatoEmergencia
  );
};

export const checkPessoaComDeficiencia = (dados) => {
  return allFieldsFilled(
    dados.deficiencia,
    dados.tipoDeficiencia
  );
};

export const checkTituloDeEleitor = (dados) => {
  return allFieldsFilled(
    dados.numeroTituloEleitor,
    dados.dataTituloEleitor,
    dados.zonaEleitoral,
    dados.secaoEleitoral
  );
};

export const checkValeTransporte = (dados) => {
  return allFieldsFilled(
    dados.vtExiste
  );
};

export const checkDependentes = (dados) => {
  return allFieldsFilled(
    dados.dependenteExiste
  );
};
