import MainButton from "./MainButton"

export default function OutlineButton({children, onClick, disabled}) {

    return (
        <MainButton
        size='large'
        disabled={disabled}
        fullWidth={true}
        style={{ 
          backgroundColor: "#fff",
          color: disabled ? '#848484' : '#003da5',
          padding: 16,
          fontWeight: 700,
          fontSize: 16,
          lineHeight: "20px",
          marginTop: 8,
          borderRadius: 8,
          borderColor: disabled ? '#848484' : '#003da5',
          borderWidth: 2
        }}
        buttonStyle='contained'
        onClick={onClick}
      >
        {children}
      </MainButton>
    )

}