import React, { useState, useEffect, useContext } from 'react';
import DadosPessoais from './Components/DadosPessoais';
import GeneroOrientacao from './Components/GeneroOrientacao';
import EstadoCivil from './Components/EstadoCivil';
import Etnia from './Components/Etnia';
import GrauInstrucao from './Components/GrauInstrucao';
import PessoaComDeficiencia from './Components/PessoaComDeficiencia';
import Endereco from './Components/Endereco';
import InformacoesDeContato from './Components/InformacoesContato';
import Dependentes from './Components/Dependentes';
import DadosBancarios from './Components/DadosBancarios';
import ValeTransporte from './Components/ValeTransporte';
import CarteiraDeTrabalho from './Components/CarteiraDeTrabalho';
import DocumentoDeIdentidade from './Components/DocumentoDeIdentidade';
import TituloDeEleitor from './Components/TituloDeEleitor';
import CertificadoReservista from './Components/CertificadoReservista';
import './Components/Componentes.css';
import useIsLinkRoute from '../../../hooks/useIsLinkRoute';

import { sendEmployeeData } from '../../../services/api';
import { FichaContext } from '../../../context/FichaContext';
import MobileContainer from '../../../components/MobileContainer'
import { useHistory, useLocation } from 'react-router-dom';
import GoBackHeader from '../../../components/goBackHeader';
import { Grid } from '@material-ui/core';
import { is } from 'date-fns/locale';

const Preenchimento = ({ onSave, onExit }) => {
  const location = useLocation();
  const fichaContext = useContext(FichaContext);
  const history = useHistory();
  const [currentComponent, setCurrentComponent] = useState('');
  const [isLoading, setLoading] = useState(false);
  const isLinkRoute = useIsLinkRoute();

  useEffect(() => {
    if (location.state) {
      setCurrentComponent(location.state.state.component);
    } else {
      console.log("Componente não encontrado ou indefinido no momento do IF")
    }
  }, [location.state]);

  if (!fichaContext) {
    return <div>O contexto de ficha não está disponível.</div>;
  }

  const { updateFicha } = fichaContext;

  const handleSaveAndExit = async (componentName, data) => {
    setLoading(true);
    const updatedFicha = await updateFicha(data);
    onSave(componentName, data);
    onExit(componentName, data);
    await handleSendData(updatedFicha);

    switch (componentName) {
      case 'dadosPessoais':
        setCurrentComponent('generoOrientacao');
        break;
      case 'generoOrientacao':
        setCurrentComponent('estadoCivil');
        break;
      case 'estadoCivil':
        setCurrentComponent('etnia');
        break;
      case 'etnia':
        setCurrentComponent('grauInstrucao');
        break;
      case 'grauInstrucao':
        setCurrentComponent('pessoaComDeficiencia');
        break;
      case 'pessoaComDeficiencia':
        setCurrentComponent('endereco');
        break;
      case 'endereco':
        setCurrentComponent('informacoesDeContato');
        break;
      case 'informacoesDeContato':
        setCurrentComponent('dependentes');
        break;
      case 'dependentes':
        setCurrentComponent('dadosBancarios');
        break;
      case 'dadosBancarios':
        setCurrentComponent('valeTransporte');
        break;
      case 'valeTransporte':
        setCurrentComponent('carteiraDeTrabalho');
        break;
      case 'carteiraDeTrabalho':
        setCurrentComponent('documentoDeIdentidade');
        break;
      case 'documentoDeIdentidade':
        setCurrentComponent('tituloDeEleitor');
        break;
      case 'tituloDeEleitor':
        setCurrentComponent('certificadoReservista');
        break;
      case 'certificadoReservista':
        setCurrentComponent('onExit');
        break;
      case 'onExit':
        setCurrentComponent('onExit');
        isLinkRoute ? history.push('/link/ficha-cadastral') : history.push('/auth/ficha-cadastral');
        break;
      default:
        break;
    }

    setLoading(false);
  };

  const handleSendData = async (updatedFicha) => {
    try {
      const response = await sendEmployeeData(updatedFicha);
    } catch (error) {
      console.error('Erro ao enviar dados:', error);
    }
  };

  const renderComponent = () => {
    switch (currentComponent) {
      case 'dadosPessoais':
        return <DadosPessoais onSave={handleSaveAndExit} onExit={handleSaveAndExit} setLoading={setLoading} isLoading={isLoading} />;
      case 'generoOrientacao':
        return <GeneroOrientacao onSave={handleSaveAndExit} onExit={handleSaveAndExit} setLoading={setLoading} isLoading={isLoading} />;
      case 'estadoCivil':
        return <EstadoCivil onSave={handleSaveAndExit} onExit={handleSaveAndExit} setLoading={setLoading} isLoading={isLoading} />;
      case 'etnia':
        return <Etnia onSave={handleSaveAndExit} onExit={handleSaveAndExit} setLoading={setLoading} isLoading={isLoading} />;
      case 'grauInstrucao':
        return <GrauInstrucao onSave={handleSaveAndExit} onExit={handleSaveAndExit} setLoading={setLoading} isLoading={isLoading} />;
      case 'pessoaComDeficiencia':
        return <PessoaComDeficiencia onSave={handleSaveAndExit} onExit={handleSaveAndExit} setLoading={setLoading} isLoading={isLoading} />;
      case 'endereco':
        return <Endereco onSave={handleSaveAndExit} onExit={handleSaveAndExit} setLoading={setLoading} isLoading={isLoading} />;
      case 'informacoesDeContato':
        return <InformacoesDeContato onSave={handleSaveAndExit} onExit={handleSaveAndExit} setLoading={setLoading} isLoading={isLoading} />;
      case 'dependentes':
        return <Dependentes onSave={handleSaveAndExit} onExit={handleSaveAndExit} setLoading={setLoading} isLoading={isLoading} />;
      case 'dadosBancarios':
        return <DadosBancarios onSave={handleSaveAndExit} onExit={handleSaveAndExit} setLoading={setLoading} isLoading={isLoading} />;
      case 'valeTransporte':
        return <ValeTransporte onSave={handleSaveAndExit} onExit={handleSaveAndExit} setLoading={setLoading} isLoading={isLoading} />;
      case 'carteiraDeTrabalho':
        return <CarteiraDeTrabalho onSave={handleSaveAndExit} onExit={handleSaveAndExit} setLoading={setLoading} isLoading={isLoading} />;
      case 'documentoDeIdentidade':
        return <DocumentoDeIdentidade onSave={handleSaveAndExit} onExit={handleSaveAndExit} setLoading={setLoading} isLoading={isLoading} />;
      case 'tituloDeEleitor':
        return <TituloDeEleitor onSave={handleSaveAndExit} onExit={handleSaveAndExit} setLoading={setLoading} isLoading={isLoading} />;
      case 'certificadoReservista':
        return <CertificadoReservista onSave={handleSaveAndExit} onExit={handleSaveAndExit} setLoading={setLoading} isLoading={isLoading} />;
      default:
        return <div>Selecione uma opção</div>;
    }
  };

  return (
    <MobileContainer style={{ padding: 0, backgroundColor: "#F3F4F6" }}>
      <Grid style={{ marginBottom: 16 }}>
        <GoBackHeader
          onGoBack={() => isLinkRoute ? history.push("/link/ficha-cadastral") : history.push("/auth/ficha-cadastral")}
        />
      </Grid>
      {renderComponent()}
    </MobileContainer>
  );
};

export default Preenchimento;
