import { useEffect, useState } from "react"

import { useHistory } from "react-router-dom"

import { Grid, Typography, TextField } from "@material-ui/core"

import { makeStyles } from "@material-ui/core/styles"

import NumberFormat from "react-number-format"

import useAlert from "../../hooks/useAlert"
import Header from "../../components/Header"
import MainPaper from "../../components/MainPaper"
import { swalAlert } from "../../helpers/swalAlert"
import useVacancies from "../../hooks/useVacancies"
import MainButton from "../../components/MainButton"
import VacancyInfo from "../../components/VacancyInfo"
import { validateCpf } from "../../helpers/validation"
import BackdropLoading from "../../components/BackdropLoading"
import MobileContainer from "../../components/MobileContainer"
import { TakeVacancy, SendLink } from "../../services/api"
import { requestVacanciesByCostCenter } from "../../services/getVacancies"
import { removeCpfFormat, checkSpecialCharactersOrNumbers, formatDateBugFix } from "../../helpers/format"
import GoBackHeader from "../../components/goBackHeader"
import useModal from "../../hooks/useModal"
import DeleteClearButton from "../../components/DeleteClearButton"

const useStyles = makeStyles(theme => ({
  paperContainer: {
    padding: 12,
    // height: "-webkit-fill-available",
  },
  card: {
    padding: 12,
    borderRadius: 8,
    marginBottom: 12,
  },
  job: {
    marginLeft: 8,
    fontWeight: 700,
  },
  vacancySubtitles: {
    color: "#000",
    fontWeight: 700,
  },
  takeVacancy: {
    marginLeft: 16,
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  documentsOnboarding: {
    color: "#000",
    marginLeft: 24,
    marginBottom: 4,
    fontWeight: 700,
  },
  detailsGrid: {
    marginBottom: 8,
  },
  title: {
    fontSize: 16,
    lineHeight: "30px",
    fontWeight: 600,
    color: '#1d3054'
  },
  description: {
    fontSize: 14,
    lineHeight: "20px",
    color: '#4d4d4d'
  },
}))

export default function VacancyDetails() {
  const classes = useStyles()

  const history = useHistory()

  const { modal, setModal } = useModal()

  const { vacancies, setVacancies } = useVacancies()

  const [cpf, setCpf] = useState("")

  const [name, setName] = useState("")

  const [phoneNumber, setPhoneNumber] = useState("");

  const [email, setEmail] = useState("");

  const { alert, setAlert } = useAlert()

  const [vacancy, setVacancy] = useState("")

  localStorage.setItem("page", JSON.stringify(0))

  useEffect(() => {
    localStorage.setItem("backPath", "/auth/vagas-direcionadas");

    const selectedVacancy = JSON.parse(localStorage.getItem("selectedVacancy"));
    setVacancy(selectedVacancy);

    if (!name && !cpf && !phoneNumber && !email) {
      setName(selectedVacancy?.empregado?.nome || "");
      setCpf(removeCpfFormat(selectedVacancy?.empregado?.cpf || ""));
      setEmail(selectedVacancy?.empregado?.email || "");
      setPhoneNumber(selectedVacancy?.empregado?.telefone || "");
    }
  }, []);

  const disableTakeResponsibilityButton = () => {
    if (
      !name.length ||
      cpf.length < 11 ||
      checkSpecialCharactersOrNumbers(name)
    ) {
      return true
    }
  }

  const disableSendLink = () => {

    if (
      !name.length ||
      cpf.length < 11 ||
      checkSpecialCharactersOrNumbers(name) ||
      !email.length ||
      !phoneNumber.length ||
      !validateEmail(email)
    ) {
      return true
    }
  }

  const handleDeleteVacancy = () => {
    localStorage.setItem("backPath", "/auth/detalhes-vaga")
    setModal({ ...modal, deleteVacancy: true })
  }

  const handleCpfValidation = () => {
    if (cpf.length === 11) {
      if (!validateCpf(cpf)) return true
    }
  }

  /* const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
    
    const match = cleanedPhoneNumber.match(/^(\d{2})(\d{5}|\d{4})(\d{4})$/);
    
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return cleanedPhoneNumber;
  }; */


  const validateEmail = (email) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  };

  const formatDateToMMDDYYYY = (dateStr) => {
    const dateStrFormatted = formatDateBugFix(dateStr);
    const [day, month, year] = dateStrFormatted.split('/');
    return `${month}/${day}/${year}`;
  };

  const getDateAfter60Days = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 60);
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const year = currentDate.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const apiSendLink = async () => {
    setAlert({ ...alert, waitingResponse: true });

    const data = {
      salario: vacancy.salario.toString(),
      numerO_SOLICITACAO: vacancy.nrChamado,
      tipO_MAO_DE_OBRA: vacancy.tipoMaoDeObra,
      estado: vacancy.estado,
      funcaO_DESCRICAO: vacancy.nome || "Descrição não disponível",
      //data sendo enviada no formato MM/DD/YYYY para o fluig
      datA_ABERTURA: formatDateToMMDDYYYY(vacancy.dataAbertura),
      obrA_DESCRICAO: vacancy.obra,
      responsaveL_RECOLHIMENTO_CODIGO: vacancy.responsavelRecolhimentoCodigo,
      responsaveL_RECOLHIMENTO_DESCRICAO: vacancy.responsavelRecolhimento,
      horario: vacancy.horario,
      statuS_FLUIG: vacancy.status,
      canceladO_ONBOARDING: "false",
      cpCodObra: vacancy.codigoObra,
      nomE_CANDIDATO: name,
      cpf: cpf,
      cpCadExternoEmailPessoal: email,
      //data sendo enviada no formato MM/DD/YYYY para o fluig
      cpDataAdmissao: getDateAfter60Days(),
      telefonE_1: phoneNumber,
      telefonE_2: "",
      linkvagas: "true"
    };

    try {
      const response = await SendLink(data);

      if (response && response.data && response.data.link) {
        swalAlert("success", "Link enviado com sucesso!", "OK, ENTENDI");
        setTimeout(() => {
          setAlert({ ...alert, waitingResponse: false });
          history.push("/auth/vagas-direcionadas");
        }, 1000);
      } else {
        console.error("Erro: O link não foi gerado corretamente:", response);
        setAlert({ ...alert, waitingResponse: false });
        setTimeout(() => history.push("/auth/vagas-direcionadas"), 1000);
        throw new Error("O link não foi gerado corretamente.");
      }

    } catch (error) {
      console.error("Erro capturado no catch:", error);
      setAlert({ ...alert, waitingResponse: false });
      const errorMessage = error.response?.data?.message || error.message || "Erro ao enviar o link";
      swalAlert("error", errorMessage, "TENTAR NOVAMENTE");
    }
  };

  const apiSubmit = async () => {
    setAlert({ ...alert, waitingResponse: true })

    let response = await TakeVacancy(
      localStorage.getItem("externalId"),
      vacancy?.nrChamado,
      cpf,
      name
    )

    if (response?.status >= 200 && response?.status < 300) {
      localStorage.setItem("selectedVacancy", JSON.stringify(vacancy))

      let vacanciesResponse = await requestVacanciesByCostCenter()

      setVacancies(vacanciesResponse)

      setAlert({ ...alert, waitingResponse: false })

      swalAlert("success", `A vaga <strong>${vacancy?.nome}</strong> foi assumida!`, "OK, ENTENDI")

      setTimeout(() => history.push("/auth/home"), 1000)
    } else {
      setAlert({ ...alert, waitingResponse: false })

      swalAlert("error", response, "TENTAR NOVAMENTE")
    }
  }

  return (
    <MobileContainer style={{ padding: 0, backgroundColor: "#F3F4F6" }}>
      <Grid style={{ backgroundColor: "#1C2F54" }}>
        <Header />
      </Grid>
      <Grid style={{ marginBottom: 16 }}>
        <GoBackHeader
          onGoBack={() => history.push("/auth/vagas-direcionadas")}
        />
      </Grid>

      <Grid style={{ marginRight: 16, marginLeft: 16, marginBottom: 16 }}>
        <Typography variant="h6" className={classes.title}>
          Deseja assumir essa vaga?
        </Typography>
        <Typography variant="body2" className={classes.description}>
          Leia as informações e veja se está tudo correto antes de assumir.
        </Typography>
      </Grid>

      <Grid style={{ marginRight: 16, marginLeft: 16 }}>
        <MainPaper
          height='100%'
          overflow
          position="static"
          transform="none"
        >
          <Grid container className={classes.paperContainer}>
            <>
              <Grid container className={classes.card}>
                <VacancyInfo />
                <>
                  <Grid container>
                    <Typography variant="h6" className={classes.title}>
                      Empregado *
                    </Typography>

                    <TextField
                      required
                      fullWidth
                      size="small"
                      margin="dense"
                      variant="outlined"
                      autoComplete="off"
                      label=""
                      value={name}
                      error={checkSpecialCharactersOrNumbers(name)}
                      onChange={(event) => setName(event.target.value)}
                      helperText={
                        checkSpecialCharactersOrNumbers(name) &&
                        "Caracteres especiais ou números não são permitidos."
                      }
                    />
                  </Grid>

                  <Grid container>

                    <Typography variant="h6" className={classes.title}>
                      CPF *
                    </Typography>
                    <NumberFormat
                      required
                      fullWidth
                      type="tel"
                      label=""
                      size="small"
                      margin="dense"
                      variant="outlined"
                      autoComplete="off"
                      customInput={TextField}
                      format="###.###.###-##"
                      value={cpf}
                      error={handleCpfValidation()}
                      helperText={handleCpfValidation() && "Digite um CPF válido."}
                      onChange={(event) => setCpf(removeCpfFormat(event.target.value))}
                    />
                  </Grid>

                  <Grid container>
                    <Typography variant="h6" className={classes.title}>
                      Telefone
                    </Typography>

                    <TextField
                      required
                      fullWidth
                      size='small'
                      margin='dense'
                      variant='outlined'
                      autoComplete='off'
                      label=''
                      type="tel"
                      inputProps={{ inputMode: 'numeric', maxLength: 18 }}
                      value={phoneNumber}
                      onChange={(event) => setPhoneNumber(event.target.value)}
                      onBlur={() => {
                        if (phoneNumber.trim() === "") {
                          setPhoneNumber("");
                        }
                      }}
                    />
                  </Grid>

                  <Grid container>
                    <Typography variant="h6" className={classes.title}>
                      E-mail
                    </Typography>

                    <TextField
                      required
                      fullWidth
                      size='small'
                      margin='dense'
                      variant='outlined'
                      autoComplete='off'
                      label=''
                      value={email}
                      onChange={event => setEmail(event.target.value)}
                      onBlur={() => {
                        if (email.trim() === "") {
                          setEmail("");
                        }
                      }}
                      error={!validateEmail(email) && email.length > 0}
                      helperText={!validateEmail(email) && email.length > 0 && "Digite um e-mail válido."}
                      style={{ marginBottom: 12 }}
                      className='textField'
                      type='email'
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>


                  <MainButton
                    size='medium'
                    fullWidth={true}
                    onClick={apiSubmit}
                    style={{ marginBottom: 12, marginTop: 12, padding: 16 }}
                    disabled={disableTakeResponsibilityButton()}

                  >
                    ASSUMIR VAGA
                  </MainButton>

                  <MainButton
                    size='medium'
                    fullWidth={true}
                    onClick={() => {
                      apiSendLink();
                    }}
                    style={{ marginBottom: 12, padding: 16 }}
                    disabled={disableSendLink()}
                  >
                    ENVIAR LINK
                  </MainButton>

                  <MainButton
                    size='medium'
                    fullWidth={true}
                    onClick={handleDeleteVacancy}
                    buttonStyle='errorOutlined'
                    style={{ padding: 16 }}
                  >
                    DELETAR VAGA
                  </MainButton>
                </>
              </Grid>
            </>
          </Grid>
        </MainPaper>
      </Grid>

      <BackdropLoading open={alert.waitingResponse} />
      <DeleteClearButton
        open={modal.deleteVacancy}
        close={() => setModal({ ...modal, deleteVacancy: false })}
        typeButton="deleteVacancy"
      />
    </MobileContainer>
  )
}
