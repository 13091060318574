
import MainButton from "./MainButton"

export default function ConfirmButton({ children, onClick, disabled = false, bordered = false }) {

  return (
    <MainButton
      size='large'
      disabled={disabled}
      type='submit'
      fullWidth={true}
      style={{
        backgroundColor: disabled ? '#d1d1d1' : '#003da5',
        color: disabled ? '#848484' : '#ffffff',
        padding: 16,
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "20px",
        marginTop: 8,
        borderRadius: 8,
        borderColor: disabled ? '#d1d1d1' : '#003da5',
        borderWidth: bordered ? 2 : 0
      }}
      buttonStyle='contained'
      onClick={onClick}
    >
      {children}
    </MainButton>
  )

}