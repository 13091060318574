import { createContext, useState, ReactNode, useEffect } from "react"

type VacanciesTypes = {
  vacancies?: Array<object>
  setVacancies?: (vacancies: Array<object>) => void
}

type VacanciesTypesProps = {
  children: ReactNode
}

export const VacanciesContext = createContext({} as VacanciesTypes)

const VacanciesProvider = (props: VacanciesTypesProps) => {
  const [vacancies, setVacancies] = useState<Array<object>>()

  return (
    <VacanciesContext.Provider
      value={{
        vacancies,
        setVacancies,
      }}
    >
      {props.children}
    </VacanciesContext.Provider>
  )
}

export default VacanciesProvider
