import { createContext, ReactNode, useState } from "react"

type TabsTypes = {
  tab?: string
  setTab?: (tab: string) => void
}

type TabsTypesProps = {
  children: ReactNode
}

export const TabsContext = createContext({} as TabsTypes)

const TabsProvider = (props: TabsTypesProps) => {
  const [tab, setTab] = useState<string>("1")

  return (
    <TabsContext.Provider
      value={{
        tab,
        setTab,
      }}
    >
      {props.children}
    </TabsContext.Provider>
  )
}

export default TabsProvider
