import { createContext, useState, ReactNode } from "react"

type CostCentersTypes = {
  costCenters?: Array<object>
  setCostCenters?: (costCenters: Array<object>) => void
}

type CostCentersTypesProps = {
  children: ReactNode
}

export const CostCentersContext = createContext({} as CostCentersTypes)

const CostCentersProvider = (props: CostCentersTypesProps) => {
  const [costCenters, setCostCenters] = useState<Array<object>>()

  return (
    <CostCentersContext.Provider
      value={{
        costCenters,
        setCostCenters,
      }}
    >
      {props.children}
    </CostCentersContext.Provider>
  )
}

export default CostCentersProvider
