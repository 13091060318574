import { Container } from "@material-ui/core"

import { makeStyles } from "@material-ui/core/styles"

export default function MobileContainer({ children, blueBackground, style }) {
  const useStyles = makeStyles(theme => ({
    container: {
      display: "flex",
      flexDirection: "column",
      top: blueBackground && 0,
      bottom: blueBackground && 0,
      left: blueBackground && "50%",
      width: blueBackground && "101%",
      position: blueBackground && "fixed",
      transform: blueBackground && "translateX(-50%)",
      /* background: blueBackground && theme.palette.primary.main, */
      background: blueBackground && 'linear-gradient(to bottom, #1D3054 0%, #233C6F 100%)',
      height: blueBackground ? "100vh" : window.innerHeight - 24,
      ...style,
      "&::-webkit-scrollbar": {
        width: "12px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#c8c8c8",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#ffffff",
      },
    },
  }))

  const classes = useStyles()
  return (
    <Container maxWidth='xs' className={classes.container}>
      {children}
    </Container>
  )
}
