import Button from "@material-ui/core/Button"

import { makeStyles, useTheme } from "@material-ui/core/styles"

export default function MainButton({ children, buttonStyle, noPadding, ...props}) {
  const theme = useTheme()

  const color = () => {
    if (buttonStyle === "outlined" || buttonStyle === "outlinedLight" || buttonStyle === "white")
      return theme.palette.primary.main

    if (buttonStyle === "errorOutlined") return theme.palette.secondary.main

    return "#FFF"
  }

  const background = () => {
    if (
      buttonStyle === "white" ||
      buttonStyle === "outlined" ||
      buttonStyle === "outlinedLight" ||
      buttonStyle === "errorOutlined"
    )
      return "#FFF"

    if (buttonStyle === "error") return theme.palette.secondary.main

    return theme.palette.primary.main
  }

  const borderColor = () => {
    if (buttonStyle === "outlined") return theme.palette.primary.main
    else if (buttonStyle === "outlinedLight") return theme.palette.primary.light
    else if (buttonStyle === "error" || buttonStyle === "errorOutlined")
      return theme.palette.secondary.main

    return theme.palette.primary.main
  }

  const useStyles = makeStyles({
    button: {
      color: color(),

      border: "solid 2px",

      padding: noPadding ? 0 : "6px 16px",

      background: background(),

      borderColor: borderColor(),

      "&:hover": {
        background: background(),
      },

      "&:disabled": {
        color: "#FFF",
        background: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
      },

      fontWeight: 700,

      textTransform: "none",
    },
  })

  const classes = useStyles()

  return (
    <Button className={classes.button} {...props}>
      {children}
    </Button>
  )
}
