export const getMostRecentDocument = (document) => {
  return document?.documentosDigitais?.reduce((mostRecent, currentDocument) => {
    const currentUploadDate = parseDate(currentDocument.dataUpload);
    const mostRecentUploadDate = mostRecent ? parseDate(mostRecent.dataUpload) : null;
    
    if (currentUploadDate && mostRecentUploadDate) {
      if (currentUploadDate > mostRecentUploadDate) {
        return currentDocument;
      }
    } else if (currentUploadDate) {
      return currentDocument;
    }
    
    return mostRecent;
  }, null);
}

// Função para analisar a data no formato "DD/MM/AAAA HH:MM" e criar um objeto Date
const parseDate = (dateString) => {
  if (dateString === null) {
    return null;
  }
  
  const parts = dateString.split(/[\s/,:]+/); // Dividindo a string em partes usando espaços, barras e dois-pontos como separadores

  if (parts.length === 5) {
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);
    const hour = parseInt(parts[3], 10);
    const minute = parseInt(parts[4], 10);

    return new Date(year, month, day, hour, minute);
  }
  
  return null;
}



export const statusText = {
  "Aprovado Textract": "Aprovado",
  "Aprovado": "Aprovado",
  "Aprovado Sem Textract": "Pré Aprovado",
  "Aguardando Textract": "Processando",
  "Pendente": "Pendente",
  "Reprovado": "Recusado",
};