import React, { useEffect, useState } from "react"

import { useHistory, useLocation } from "react-router-dom"

import { makeStyles } from "@material-ui/core/styles"

import { Grid, TextField, Typography, InputAdornment } from "@material-ui/core"

import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined"
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined"
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined"

import { UserLogin } from "../../services/api"
import CallCenter from "../../components/CallCenter"
import MainButton from "../../components/MainButton"
import MobileContainer from "../../components/MobileContainer"
import ButtonCircularLoading from "../../components/ButtonCircularLoading"

import Turnstile from "react-turnstile";
import { clearLocalStorage } from "../../helpers/clearLocalStorage"

function Login() {
  const history = useHistory()

  const [userId, setUserId] = useState("")

  const [password, setPassword] = useState("")

  const [background, setBackground] = useState("blue")

  const [showPassword, setShowPassword] = useState(false)

  const [tokenCloud, setTokenCloud] = useState()
  const query = useQuery();

  localStorage.setItem("page", 0)

  const useStyles = makeStyles(theme => ({
    background: {
      top: 0,
      right: 0,
      margin: 0,
      padding: 0,
      zIndex: -1,
      left: "50%",
      maxWidth: 444,
      width: "101%",
      height: "101%",
      transition: "1s",
      maxHeight: "101%",
      position: "fixed",
      objectFit: "none",
      transform: "translateX(-50%)",
      filter: background === "gray" ? "grayscale(100%) contrast(50%)" : null,
    },
    logoGrid: {
      display: "flex",
      justifyContent: "center",
      marginBottom: 16,
      borderRadius: "16px 16px 0 0",
    },
    logo: {
      width: 260,
      marginTop: 8,
      marginBottom: 8,
    },
    welcomeText: {
      color: "#1D3054",
      fontWeight: 700,
      marginBottom: 8,
      fontSize: 24,
    },
    accessText: {
      color: "#1D3054",
      marginBottom: 40,
      fontsize: 16,
    },
    inputText: {
      color: "#1D3054",
      marginBottom: 8,
      fontsize: 16,
      fontWeight: 700,
    },
    userInput: {
      borderRadius: 4,
      marginBottom: 16,
      background: "#FFF",
    },
    forgotPassword: {
      color: "#1D3054",
      cursor: "pointer",
      marginBottom: 16,
    },
    TurnstileGrid: {
      display: "flex",
      justifyContent: "center",
      marginLeft: "auto",
      marginRight: "auto",
      width: "100%",
    }
  }))

  const classes = useStyles()

  let captchaSecretKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;

  const [buttonLabel, setButtonLabel] = useState({
    label: "ENTRAR",
    style: "contained",
  })

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleSubmit = async event => {
    clearLocalStorage()

    event.preventDefault()

    setButtonLabel({
      label: <ButtonCircularLoading />,
      style: "contained",
    })

    let response = await UserLogin(userId, password, tokenCloud)

    if (response === "networkError") {
      setButtonLabel({
        label: "ENTRAR",
        style: "contained",
      })
    } else if (response?.status >= 200 && response?.status < 300) {
      localStorage.setItem("tempIdUsuario", userId)

      localStorage.setItem("tempSenha", password)

      localStorage.setItem("hiddenEmail", response.data?.hiddenEmail)

      response.data?.aceiteTermo[0]?.id > 0 && response.data?.aceiteTermo[1]?.id > 0 ? localStorage.setItem("isTermsSigned", "true") : localStorage.setItem("isTermsSigned", "false")

      localStorage.setItem("idAssinatura37", response.data?.aceiteTermo[0]?.id)
      localStorage.setItem("idAssinatura38", response.data?.aceiteTermo[1]?.id)

      history.push("/verificar-codigo")
    } else {
      const errorMessage = response || "";

      if (errorMessage.includes("O captcha informado está incorreto.")) {
        setButtonLabel({
          label: "O captcha informado está incorreto. Por favor, atualize a página e tente novamente.",
          style: "error",
        });
      } else if (errorMessage.includes("Usuário ou senha inválidos!")) {
        setButtonLabel({
          label: "Usuário ou senha inválidos!",
          style: "error",
        });
      } else if (errorMessage.includes("Número de tentativas excedido.")) {
        setButtonLabel({
          label: "Número de tentativas excedido. Por favor, aguarde 30 segundos e tente novamente.",
          style: "error",
        });
      } else if (errorMessage.includes("Usuario não tem acesso ao grupo.")) {
        setButtonLabel({
          label: "USUÁRIO SEM ACESSO AO GRUPO",
          style: "error",
        });
      } else {
        setButtonLabel({
          label: "ACESSO INVÁLIDO, ATUALIZE A PÁGINA E TENTE NOVAMENTE",
          style: "error",
        });
      }
    }
  }

  useEffect(() => {
    localStorage.setItem("tempT", tokenCloud)

    setButtonLabel({
      label: "ACESSAR",
      style: "contained",
    })

    if (userId && password) {
      setBackground("gray")
    } else {
      setBackground("blue")
    }
  }, [userId, password])

  useEffect(() => {
    const key = query.get("key")
    if (key !== null) {
      sessionStorage.setItem('url-link-key', key)
      history.push('/link/carregar')
    }
  }, [query])

  return (
    <form onSubmit={handleSubmit}>
      <MobileContainer style={{ backgroundColor: "#ffffff", padding: 0 }}>
        <Grid justifyContent="center" className={classes.logoGrid} style={{ marginLeft: "auto", marginRight: "auto", width: "100%", backgroundColor: "#1D3054" }}>
          <img alt='Logo branco' src='/assets/images/logogrupo.png' className={classes.logo} />
        </Grid>
        <div
          style={{ marginLeft: 16, marginRight: 16 }}>


          <Typography variant='h6' className={classes.welcomeText}>
            Acesso
          </Typography>

          <Typography variant='body2' className={classes.accessText}>
            Por favor, insira seus dados
          </Typography>

          <Typography className={classes.inputText}>
            E-mail
          </Typography>

          <TextField
            fullWidth
            value={userId}
            variant='outlined'
            autoComplete='username'
            placeholder='Digite o seu e-mail ou DE/TR'
            className={classes.userInput}
            onChange={event => setUserId(event.target.value)}
            InputProps={
              !userId
                ? {
                  startAdornment: (
                    <InputAdornment position='end' style={{ marginRight: 16 }}>
                      <EmailOutlinedIcon />
                    </InputAdornment>
                  ),

                  style: { fontWeight: 700 },
                }
                : {
                  endAdornment: (
                    <InputAdornment position='end'>
                      <HighlightOffIcon
                        onClick={() => setUserId("")}
                        style={{ cursor: "pointer" }}
                      />
                    </InputAdornment>
                  ),

                  style: { fontWeight: 700 },
                }
            }
          />

          <Typography className={classes.inputText}>
            Senha
          </Typography>

          <TextField
            fullWidth
            value={password}
            variant='outlined'
            placeholder='Digite a sua senha'
            className={classes.userInput}
            autoComplete='current-password'
            type={showPassword ? "text" : "password"}
            onChange={event => setPassword(event.target.value)}
            InputProps={
              !password
                ? {
                  startAdornment: (
                    <InputAdornment position='end' style={{ marginRight: 16 }}>
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),

                  style: { fontWeight: 700 },
                }
                : {
                  endAdornment: (
                    <InputAdornment position='end'>
                      {showPassword ? (
                        <VisibilityOutlinedIcon onClick={handleShowPassword} />
                      ) : (
                        <VisibilityOffOutlinedIcon onClick={handleShowPassword} />
                      )}
                    </InputAdornment>
                  ),

                  style: { fontWeight: 700 },
                }
            }
          />

          <Grid container justifyContent='flex-end'>
            <Typography
              variant='caption'
              className={classes.forgotPassword}
              onClick={() => window.open("https://portalsenha.direcional.com.br/")}
            >
              Esqueceu sua senha?
            </Typography>
          </Grid>
          <Grid className={classes.TurnstileGrid}>
            <Turnstile
              alignItems="center"
              justifyContent="center"
              sitekey={captchaSecretKey}
              onVerify={(token) => setTokenCloud(token)}
              theme="light"
            />
          </Grid>

          <MainButton
            size='large'
            type='submit'
            fullWidth={true}
            style={{ marginTop: 24, padding: 16 }}
            buttonStyle={buttonLabel.style}
            disabled={!userId || !password || typeof buttonLabel.label !== "string"}
          >
            {buttonLabel.label}
          </MainButton>

          <Grid container style={{ marginTop: "auto" }}>
            <CallCenter whiteLink />
          </Grid>
        </div>
      </MobileContainer>
    </form>
  )
}

export default Login

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
