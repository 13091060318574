import React, { useState, useContext } from 'react';
import { FichaContext } from '../../../../context/FichaContext';
import './Componentes.css';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';

const Endereco = ({ onSave, onExit, setLoading, isLoading }) => {
  const fichaContext = useContext(FichaContext);
  const [formData, setFormData] = useState({
    cep: fichaContext?.ficha.cep || '',
    tipoRUA: fichaContext?.ficha.tipoRUA || 'Rua',
    rua: fichaContext?.ficha.rua || '',
    numero: fichaContext?.ficha.numero || '',
    complemento: fichaContext?.ficha.complemento || '',
    bairro: fichaContext?.ficha.bairro || '',
    tipoBairro: fichaContext?.ficha.tipoBairro || 'Bairro',
    estado: fichaContext?.ficha.estado || '',
    municipio: fichaContext?.ficha.municipio || '',
    pais: 'Brasil',
  });

  const [cepValido, setCepValido] = useState(true);

  if (!fichaContext) {
    return null;
  }

  const handleCepChange = async (e) => {
    let { name, value } = e.target;

    value = value.replace(/\D/g, '');

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.length === 8) {
      try {
        const response = await fetch(`https://viacep.com.br/ws/${value}/json/`);
        const data = await response.json();
        if (!data.erro) {
          setFormData({
            ...formData,
            cep: value,
            rua: data.logradouro,
            bairro: data.bairro,
            estado: data.uf,
            municipio: data.localidade,
          });
          setCepValido(true);
        } else {
          console.error('CEP não encontrado');
          setCepValido(false);
        }
      } catch (error) {
        console.error('Erro ao buscar endereço:', error);
        setCepValido(false);
      }
    } else {
      setCepValido(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = () => {
    onSave('endereco', formData);
  };

  const handleExit = () => {
    onExit('onExit', formData);
  };

  const areFieldsFilled = () => {
    const {
      cep,
      tipoRUA,
      rua,
      numero,
      bairro,
      estado,
      municipio,
    } = formData;
    return cep !== ''
      && tipoRUA !== ''
      && rua !== ''
      && numero !== ''
      && bairro !== ''
      && estado !== ''
      && municipio !== ''
      && cepValido;
  };

  return (
    <>
      <Grid style={{ marginRight: 16, marginLeft: 16, marginBottom: 16 }}>
        <Typography variant="h6" className='titleComponente'>
          Endereço
        </Typography>
        <Typography variant="body2" className='descriptionComponente'>
          Etapa obrigatória <span className='asterisk'>*</span>
        </Typography>
      </Grid>
      <form className='formComponente'>
        <Grid className='gridComponente'>
          <Typography variant="h6" className='titleTypography'>
            CEP <span className='asterisk'>*</span>
          </Typography>
          <TextField
            name="cep"
            value={formData.cep}
            onChange={handleCepChange}
            label=""
            size='small'
            required
            inputProps={{ maxLength: 8 }}
            className='textField'
            error={!cepValido}
            helperText={!cepValido && formData.cep.length > 0 ? 'CEP inválido ou incompleto.' : ''}
          />
        </Grid>

        <Grid className='gridComponente'>
          <Typography variant="h6" className='titleTypography'>
            Tipo de rua <span className='asterisk'>*</span>
          </Typography>
          <FormControl className='formControl' fullWidth>
            <InputLabel className='inputLabel' id="tipoRUA-label">{formData.tipoRUA ? '' : 'Selecione'}</InputLabel>
            <Select
              labelId="tipoRUA-label"
              id="tipoRUA"
              size='small'
              name="tipoRUA"
              value={formData.tipoRUA}
              onChange={(e) => handleChange(e)}
              label=""
              className='selectField'
              required
            >
              <MenuItem value="Rua">Rua</MenuItem>
              <MenuItem value="Avenida">Avenida</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid className='gridComponente'>
          <Typography variant="h6" className='titleTypography'>
            Rua <span className='asterisk'>*</span>
          </Typography>
          <TextField
            name="rua"
            value={formData.rua}
            onChange={handleChange}
            label=""
            size='small'
            required
            inputProps={{ maxLength: 70 }}
            className='textField'
          />
        </Grid>

        <Grid className='gridComponenteRow'>
          <Grid className='gridComponenteNumber'>
            <Typography variant="h6" className='titleTypography'>
              Número <span className='asterisk'>*</span>
            </Typography>
            <TextField
              name="numero"
              value={formData.numero}
              onChange={handleChange}
              label=""
              size='small'
              required
              className='textField'
              inputProps={{ maxLength: 15 }}
            />
          </Grid>

          <Grid className='gridComponente'>
            <Typography variant="h6" className='titleTypography'>
              Complemento
            </Typography>
            <TextField
              name="complemento"
              value={formData.complemento}
              onChange={handleChange}
              label=""
              size='small'
              className='textField'
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
        </Grid>

        <Grid className='gridComponente'>
          <Typography variant="h6" className='titleTypography'>
            Bairro <span className='asterisk'>*</span>
          </Typography>
          <TextField
            name="bairro"
            value={formData.bairro}
            onChange={handleChange}
            label=""
            size='small'
            required
            className='textField'
            inputProps={{ maxLength: 50 }}
          />
        </Grid>

        <Grid className='gridComponente'>
          <Typography variant="h6" className='titleTypography'>
            Estado <span className='asterisk'>*</span>
          </Typography>
          <TextField
            name="estado"
            value={formData.estado}
            onChange={handleChange}
            label=""
            size='small'
            required
            inputProps={{ maxLength: 50 }}
            className='textField'
          />
        </Grid>

        <Grid className='gridComponente'>
          <Typography variant="h6" className='titleTypography'>
            Cidade <span className='asterisk'>*</span>
          </Typography>
          <TextField
            name="municipio"
            value={formData.municipio}
            onChange={handleChange}
            label=""
            inputProps={{ maxLength: 50 }}
            size='small'
            required
            className='textField'
          />
        </Grid>

        <Grid className='gridComponente'>
          <Typography variant="h6" className='titleTypography'>
            País <span className='asterisk'>*</span>
          </Typography>
          <TextField
            name="pais"
            value={formData.pais}
            onChange={handleChange}
            label=""
            size='small'
            required
            inputProps={{ maxLength: 50 }}
            disabled
            className='textFieldDisabled'
          />
        </Grid>
      </form>
      <div className='divButtons'>
        <Button
          className='buttonSaveNext'
          variant="contained"
          onClick={handleSave}
          disabled={!areFieldsFilled() || isLoading}
        >
          Próxima Etapa
        </Button>
        <Button className='buttonSaveExit' variant="contained" onClick={handleExit} disabled={!areFieldsFilled() || isLoading}>
          Salvar e Continuar Depois
        </Button>
      </div>
    </>
  );
};

export default Endereco;
