import { useEffect, useState } from "react"

import { useHistory } from "react-router-dom"

import { Typography, Grid } from "@material-ui/core"

import Steps from "./Steps"
import useAuth from "../../hooks/useAuth"
import MainTabs from "../../components/MainTabs"
import MobileContainer from "../../components/MobileContainer"
import { MdCancel } from "react-icons/md"
import useIsLinkRoute from "../../hooks/useIsLinkRoute"

export default function UploadDocument() {
  const history = useHistory()
  const { images, setImages } = useAuth();
  const [documentToUpload, setDocumentToUpload] = useState([])
  const isLinkRoute = useIsLinkRoute();

  useEffect(() => {
    setDocumentToUpload(JSON.parse(localStorage.getItem("documentToUpload")))
  }, [])

  const labelForMultiplePages = () => {
    if (images?.imagesArray?.length === 0)
      return `Revisar documento 1 de ${documentToUpload?.numeroPaginas}`;

    return `Revisar documento ${String(images?.imagesArray?.length)} de ${documentToUpload?.numeroPaginas}`;
  }

  const tabList = () => {
    return [{ value: "1", label: labelForMultiplePages(), cursor: "initial", component: <Steps multiplePages /> }];
  }

  const clearImagesArray = () => {
    setImages({ imagesArray: [] });
  };

  return (
    <MobileContainer blueBackground style={{ overflowY: "auto", paddingBottom: 20 }}>
      <Grid container alignItems='center' style={{ marginTop: 8, marginBottom: 8 }}>
        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
          <Typography variant='body2' style={{ color: "#FFF", textAlign: "center", marginLeft: "20%", fontSize: "16px", fontWeight: "700" }}>
            Envie o documento
          </Typography>
        </Grid>

        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} container justifyContent='flex-end'>
          <MdCancel size={30} cursor={"pointer"} color='#BFD7FF' onClick={() => {
            clearImagesArray();
            const targetRoute = isLinkRoute ? "/link/documentos/anexar" : "/auth/documentos/anexar";
            history.push(targetRoute);
          }} />
        </Grid>

      </Grid>

      <MainTabs white tabList={tabList()} marginTop={"0%"} />
    </MobileContainer>
  )
}
