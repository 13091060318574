import React, { useState, useContext, useEffect } from 'react';
import { FichaContext } from '../../../../context/FichaContext';
import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@material-ui/core';

const Etnia = ({ onSave, onExit, setLoading, isLoading }) => {
  const fichaContext = useContext(FichaContext);
  const [formData, setFormData] = useState({
    etnia: fichaContext?.ficha.etnia ?? null,
  });

  const [etniaValida, setEtniaValida] = useState(formData.etnia !== null);

  useEffect(() => {
    setEtniaValida(formData.etnia !== null);
  }, [formData.etnia]);

  if (!fichaContext) {
    return null;
  }

  const handleChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      etnia: value !== '' ? Number(value) : null,
    });
    setEtniaValida(value !== '');
  };

  const handleSave = () => {
    onSave('etnia', formData);
  };

  const handleExit = () => {
    onExit('onExit', formData);
  };

  return (
    <>
      <Grid style={{ marginRight: 16, marginLeft: 16, marginBottom: 16 }}>
        <Typography variant="h6" className='titleComponente'>
          Etnia
        </Typography>
        <Typography variant="body2" className='descriptionComponente'>
          Etapa obrigatória <span className='asterisk'>*</span>
        </Typography>
      </Grid>
      <form className='formComponente'>
        <Typography variant="h6" className='titleTypography'>
          Etnia <span className='asterisk'>*</span>
        </Typography>
        <FormControl className='formControl' component="fieldset" required>
          <RadioGroup name="etnia" value={formData.etnia} onChange={handleChange}>
            <FormControlLabel value={2} control={<Radio />} label="Branco" />
            <FormControlLabel value={4} control={<Radio />} label="Preto" />
            <FormControlLabel value={8} control={<Radio />} label="Pardo" />
            <FormControlLabel value={6} control={<Radio />} label="Amarelo" />
            <FormControlLabel value={0} control={<Radio />} label="Indígena" />
            <FormControlLabel value={10} control={<Radio />} label="Não declarado" />
          </RadioGroup>
        </FormControl>
      </form>
      <div className='divButtons'>
        <Button className='buttonSaveNext' variant="contained" onClick={handleSave} disabled={formData.etnia === null || isLoading}>
          Próxima Etapa
        </Button>
        <Button className='buttonSaveExit' variant="contained" onClick={handleExit} disabled={formData.etnia === null || isLoading}>
          Salvar e Continuar Depois
        </Button>
      </div>
    </>
  );
};

export default Etnia;
