import React, { createContext, useState, ReactNode, useEffect } from 'react';
import { Ficha } from '../pages/DocumentLink/FichaCadastral/typesFicha';
import { calculateAge } from '../helpers/validationFicha';

interface FichaContextType {
  ficha: Ficha;
  updateFicha: (newData: Partial<Ficha>) => Promise<Ficha>;
}

export const FichaContext = createContext<FichaContextType | undefined>(undefined);

const FichaProvider = ({ children }: { children: ReactNode }) => {
  const [ficha, setFicha] = useState<Ficha>(() => {
    const savedFicha = sessionStorage.getItem('ficha');
    return savedFicha ? JSON.parse(savedFicha) : {
      id: 0,
      nome: null,
      cpf: null,
      email: null,
      telefone: null,
      naturalidade: null,
      estadoNatal: null,
      codeTD: null,
      dtNascimento: null,
      telefoneEmergencia: null,
      nomeContatoEmergencia: null,
      estadoCivil: null,
      etnia: null,
      sexoColaborador: null,
      identidadeGenero: null,
      orientacaoSexual: null,
      grauInstrucao: null,
      deficiencia: null,
      tpDeficienciaFisica: null,
      tpDeficienciaAuditiva: null,
      tpDeficienciaFala: null,
      tpDeficienciaVisual: null,
      tpDeficienciaMental: null,
      tpDeficienciaReabilitado: null,
      tpDeficienciaIntelectual: null,
      numeroIdentidade: null,
      estadoEmissorIdentidade: null,
      dataEmissaoIdentidade: null,
      orgaoEmissorIdentidade: null,
      nacionalidade: null,
      registroNacionalEstrangeiros: null,
      dataValidadeRegistroNacionalEstrangeiros: null,
      numeroTituloEleitor: null,
      dataTituloEleitor: null,
      zonaEleitoral: null,
      secaoEleitoral: null,
      numeroCtps: null,
      numeroSerieCtps: null,
      numeroCertificadoReservista: null,
      situacaoMilitar: null,
      numeroPisPasep: null,
      cep: null,
      rua: null,
      tipoRUA: null,
      numero: null,
      complemento: null,
      tipoBairro: null,
      bairro: null,
      municipio: null,
      estado: null,
      pais: null,
      bancoPagamento: null,
      agenciaPagamento: null,
      numeroConta: null,
      operacaoBancaria: null,
      fichaCadastralEnviada: null,
      dependenteExiste: null,
      dependente: null,
      vtExiste: null,
      valeTransporte: null,
      criado: new Date().toISOString(),
      alterado: new Date().toISOString(),
      deletado: "",
    };
  });

  useEffect(() => {
    sessionStorage.setItem('ficha', JSON.stringify(ficha));
  }, [ficha]);

  const convertToDate = (dateStr: string): Date | null => {
    const [day, month, year] = dateStr.split('/').map(Number);
    if (day && month && year) {
      return new Date(year, month - 1, day); // mês é 0-based
    }
    return null;
  };

  const updateFicha = (newData: Partial<Ficha>): Promise<Ficha> => {
    return new Promise<Ficha>((resolve) => {
      setFicha((prevFicha) => {
        const updatedFicha = {
          ...prevFicha,
          ...newData,
          alterado: new Date().toISOString(),
        };

        const nacionalidade = updatedFicha.nacionalidade?.toLowerCase();
        const sexo = updatedFicha.sexoColaborador;
        const dtNascimento = updatedFicha.dtNascimento ? convertToDate(updatedFicha.dtNascimento) : null;
        const idade = calculateAge(dtNascimento);

        if (nacionalidade && nacionalidade !== 'brasileira') {
          updatedFicha.numeroIdentidade = '';
          updatedFicha.dataEmissaoIdentidade = '';
          updatedFicha.estadoEmissorIdentidade = '';
          updatedFicha.orgaoEmissorIdentidade = '';
          updatedFicha.numeroTituloEleitor = '';
          updatedFicha.dataTituloEleitor = '';
          updatedFicha.zonaEleitoral = '';
          updatedFicha.secaoEleitoral = '';
          updatedFicha.numeroCertificadoReservista = '';
          updatedFicha.situacaoMilitar = '';
        }

        if (nacionalidade === 'brasileira' && sexo === 'F') {
          updatedFicha.numeroCertificadoReservista = '';
          updatedFicha.situacaoMilitar = '';
        }

        if (sexo === 'M' && (idade !== null && idade !== 0) && (idade < 18 || idade > 45)) {
          updatedFicha.numeroCertificadoReservista = '';
          updatedFicha.situacaoMilitar = '';
        }

        if ((idade !== null && idade !== 0) && (idade < 18 || idade > 70)) {
          updatedFicha.numeroTituloEleitor = '';
          updatedFicha.dataTituloEleitor = '';
          updatedFicha.zonaEleitoral = '';
          updatedFicha.secaoEleitoral = '';
        }

        resolve(updatedFicha);
        sessionStorage.setItem('ficha', JSON.stringify(updatedFicha));
        return updatedFicha;
      });
    });
  };

  return (
    <FichaContext.Provider value={{ ficha, updateFicha }}>
      {children}
    </FichaContext.Provider>
  );
};

export default FichaProvider;
