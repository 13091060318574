import { useEffect } from "react";
import useDocumentLink from "../../../hooks/useDocumentLink";
import { useHistory } from "react-router-dom";

const CheckEmptyUser = () => {
    const { user } = useDocumentLink();
    const history = useHistory();

    useEffect(() => {
        if (user.email === "") history.push("/link/carregar")
    }, [user, history])

    return null;
}

export default CheckEmptyUser;