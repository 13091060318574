import { Grid, Typography } from '@material-ui/core';
import { Check, ChevronRight } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { MdEditNote } from 'react-icons/md';

const useStyles = makeStyles(() => ({
  card: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: 16,
    borderRadius: 8,
    boxShadow: '0px 2px 4px -2px rgba(77, 77, 77, 0.06), 0px 4px 8px -2px rgba(77, 77, 77, 0.10)',
    marginBottom: 8,
    textDecoration: 'none',
    color: 'inherit',
    cursor: (props) => (props.disabled ? 'default' : 'pointer'),
    opacity: (props) => (props.disabled ? 0.8 : 1),
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#e8e8e8',
    height: 40,
    width: 40,
    borderRadius: 4,
  },
  cardTitle: {
    fontSize: 14,
    fontWeight: 700,
    color: (props) => (props.disabled ? '#848484' : '#1d3054'),
  },
  cardSubtitle: {
    fontSize: 12,
    color: '#4d4d4d',
  },
  chevronContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 4,
  },
  cardClickable: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginLeft: 8,
    marginRight: 8,
  },
}));

const CardFichaCadastral = ({ onClick, title, subtitle, disabled = false, filled = false }) => {
  const classes = useStyles({ disabled });

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      if (!disabled) onClick();
    }
  };

  const iconColor = disabled ? '#848484' : '#1d3054';

  return (
    <div
      className={classes.card}
      onClick={!disabled ? onClick : undefined}
      role="button"
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      <Grid item className={classes.icon}>
        {filled ? (
          <Check style={{ color: '#15803D', fontSize: 20, backgroundColor: '#F0FDF4', width: 40, height: 40, borderRadius: 4, padding: 8 }} />
        ) : (
          <MdEditNote style={{ color: iconColor, fontSize: 20, width: 40, height: 40, borderRadius: 4, padding: 8 }} />
        )}
      </Grid>
      <div className={classes.cardClickable}>
        <div className={classes.textContainer}>
          <Typography variant="body2" className={classes.cardTitle}>
            {title}
          </Typography>
          <Typography variant="body2" className={classes.cardSubtitle}>
            {subtitle}
          </Typography>
        </div>
        <div className={classes.chevronContainer}>
          <ChevronRight style={{ color: '#1d3054' }} />
        </div>
      </div>
    </div>
  );
};

export default CardFichaCadastral;
