import { GetVacanciesByCostCenter } from "./api";

export const requestVacanciesByCostCenter = async () => {
  let deCode = localStorage.getItem("externalId");

  let selectedCostCenterJSON = localStorage.getItem("selectedCostCenter");
  if (selectedCostCenterJSON === null) {
    console.warn("selectedCostCenter not found in localStorage");
    return null;
  }

  let selectedCostCenter;
  try {
    selectedCostCenter = JSON.parse(selectedCostCenterJSON);
  } catch (e) {
    console.error("Failed to parse selectedCostCenter JSON", e);
    return null;
  }

  if (!selectedCostCenter.descricao) {
    console.warn("descricao property not found in selectedCostCenter");
    return null;
  }

  let selectedCostCenterDescription = selectedCostCenter.descricao;

  let response = await GetVacanciesByCostCenter(deCode, selectedCostCenterDescription);

  if (response?.status >= 200 && response?.status < 300) {
    localStorage.setItem("vacanciesByCostCenter", JSON.stringify(response.data));
    return response.data;
  } else {
    console.error("Failed to fetch vacancies by cost center", response);
    return null;
  }
};
