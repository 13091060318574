import { useHistory } from "react-router-dom"

import { Grid } from "@material-ui/core"

import { makeStyles } from "@material-ui/core/styles"

import { FiArrowLeft } from "react-icons/fi"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined"
import ReplayOutlinedIcon from "@material-ui/icons/ReplayOutlined"

import useAlert from "../hooks/useAlert"
import { logout } from "../services/auth"
import useVacancies from "../hooks/useVacancies"
import { GetCostCenters } from "../services/api"
import { requestVacanciesByCostCenter } from "../services/getVacancies"

export default function Header({ backArrow, path, white, isFicha }) {

  const { vacancies, setVacancies } = useVacancies()

  const { alert, setAlert } = useAlert()

  const history = useHistory()

  const useStyles = makeStyles({
    container: {
      marginBottom: 8,
      backgroundColor: "#1C2F54",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingRight: 16,
    },
    logoImage: {
      width: 160,
    },
    title: {
      color: "#1C2F54",
    },
    iconsColor: {
      marginTop: 4,
      color: "#fff",
      cursor: "pointer",
      height: 24,
      marginLeft: 12,
    },
  })

  const classes = useStyles()

  const exitApp = () => {
    logout()
    history.push("/")
  }

  const refreshCostCenters = async () => {
    setAlert({ ...alert, waitingResponse: true })

    let costCentersResponse = await GetCostCenters(localStorage.getItem("externalId"))

    if (costCentersResponse?.status >= 200 && costCentersResponse?.status < 300) {
      localStorage.setItem("costCenters", JSON.stringify(costCentersResponse.data))

      let vacanciesResponse = await requestVacanciesByCostCenter()

      setVacancies(vacanciesResponse)

      setAlert({ ...alert, waitingResponse: false })
    } else {
      setAlert({ ...alert, waitingResponse: false })
    }
  }

  return (
    <Grid container className={classes.container}>
      {backArrow && (
        <FiArrowLeft
          size={32}
          color='#FFF'
          style={{ marginTop: 8 }}
          onClick={() => history.push(path)}
        />
      )}

      <Grid item>
        <img src="/assets/images/logogrupo.png" alt="Logo" className={classes.logoImage} />
      </Grid>

      <Grid item>
        <HomeOutlinedIcon
          fontSize='large'
          className={classes.iconsColor}
          onClick={() => history.push("/auth")}
        />

        {!isFicha && (
          <ReplayOutlinedIcon
            fontSize='large'
            onClick={refreshCostCenters}
            className={classes.iconsColor}
          />
        )}

        <ExitToAppIcon fontSize='large' className={classes.iconsColor} onClick={exitApp} />
      </Grid>
    </Grid>
  )
}
