import Swal from "sweetalert2"
import { formatErrorMessages } from "./format"

export const swalAlert = (icon, message, buttonText) => {
  Swal.fire({
    icon: icon,
    allowOutsideClick: false,
    confirmButtonColor: "#29333C",
    html: `${formatErrorMessages(message)}`,
    confirmButtonText: `        ${buttonText}        `,
  })
}
