/* 
    REGEX: 

      \w (word character) matches any single letter, number or underscore (same as [a-zA-Z0-9_]).

      ref: https://www3.ntu.edu.sg/home/ehchua/programming/howto/Regexe.html#zz-2.6
    
*/

import { format } from "date-fns"

import { frontendEnvironment } from "../services/api"

export const hideEmailChars = email => {
  if (email !== undefined && email !== null) {
    let [emailToHide, domain] = email.split("@")

    const firstChar = emailToHide[0]

    const lastTwoChars = emailToHide.substring(emailToHide.length - 2, emailToHide.length)

    const hiddenChars = emailToHide.substring(1, emailToHide.length - 2).replace(/\w/g, "*")

    return `${firstChar}${hiddenChars}${lastTwoChars}@${domain}`
  }
}

export const hidePhoneChars = phone => {
  if (phone !== undefined && phone !== null) {
    const countryCode = "+55"

    const ddd = phone.substring(0, 2)

    const firstNine = phone.substring(2, 3)

    const lastTwoChars = phone.substring(phone.length - 2, phone.length)

    const hiddenChars = phone.substring(3, phone.length - 2).replace(/\w/g, "*")

    return `${countryCode} ${ddd} ${firstNine}${hiddenChars}${lastTwoChars}`
  }
}

export const formatPhoneNumbers = phone => {
  if (phone !== undefined && phone !== null) {
    const ddd = phone.substring(0, 2)
    const firstFiveNumbers = phone.substring(2, 7)
    const lastFourNumbers = phone.substring(7, phone.length)

    return `(${ddd}) ${firstFiveNumbers}-${lastFourNumbers}`
  }
}

export const removePhoneFormat = phone => {
  if (phone !== undefined && phone !== null) {
    const ddd = phone.substring(5, 7)

    const phoneNumberWithFirstNine = phone.substring(9, phone.length)

    return `${ddd}${phoneNumberWithFirstNine}`
  }
}

export const removeCpfFormat = cpf => {
  if (cpf !== null && cpf !== undefined) return cpf.replace(/\D/g, "")
  return ""
}

export const showFormatedCpf = cpf => {
  if (cpf !== null && cpf !== undefined) {
    const firstThreeNumbers = cpf.substring(0, 3)

    const secondThreeNumbers = cpf.substring(3, 6)

    const thirdThreeNumbers = cpf.substring(6, 9)

    const lastTwoNumbers = cpf.substring(9, cpf.length)

    return `${firstThreeNumbers}.${secondThreeNumbers}.${thirdThreeNumbers}-${lastTwoNumbers}`
  }
  return ""
}

export const formatDateToAmericanPattern = date => {
  if (date !== undefined && date !== null) return format(date, "yyyy-MM-dd")
}

export const formatDateToBrazilianPattern = date => {
  if (date !== undefined && date !== null) {
    date = date.split("T")

    const day = date[0].split("-")[2]
    const month = date[0].split("-")[1]
    const year = date[0].split("-")[0]

    return `${day}/${month}/${year}`
  }
}

export const formatDateBugFix = (dateStr) => {
  const regexIso = /^\d{4}\/\d{2}\/\d{2}$/;
  const regexIsoWithTime = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;

  if (regexIso.test(dateStr)) {
    let [year, month, day] = dateStr.split('/');
    return `${day}/${month}/${year}`;
  } else if (regexIsoWithTime.test(dateStr)) {
    let [year, month, day] = dateStr.split('T')[0].split('-');
    return `${day}/${month}/${year}`;
  }

  return dateStr;
};



export const formatAccessLink = externalId => {
  if (externalId !== undefined && externalId !== null)
    return `${frontendEnvironment()}id=${externalId}`
}

export const showGreetingsByHour = () => {
  let currentHour = new Date().getHours()

  if (currentHour >= 6 && currentHour < 12) return "Bom dia,"

  if (currentHour >= 12 && currentHour < 18) return "Boa tarde,"
  else return "Boa noite,"
}

export const checkIfStringIsBlankSpacesOnly = string => {
  return !string.trim()
}

export const formatErrorMessages = message => {
  if (message?.title) {
    return message.title;
  } else if (message?.data) {
    return message.data;
  } else if (typeof message === 'string') {
    if (message.includes("Gateway")) {
      return "O servidor encontrou um erro temporário e não pôde concluir sua solicitação, tente novamente.";
    } else if (message.includes("Código de verificação")) {
      return "Código inválido ou expirado.";
    } else {
      return message;
    }
  } else if (message === undefined) {
    return "O servidor encontrou um erro temporário e não pôde concluir sua solicitação, tente novamente.";
  } else {
    return "Ocorreu um erro desconhecido.";
  }
};


export const formatToBrazilianCurrency = value => {
  if (value !== null && value !== undefined)
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value)

  return ""
}

export const calculateProgressBar = (sentDocuments, totalDocuments) => {
  if (sentDocuments === 0) {
    return 0
  } else if (sentDocuments > totalDocuments) {
    return 100
  } else {
    return (sentDocuments / totalDocuments) * 100
  }
}

export const checkSpecialCharactersOrNumbers = stringToCheck => {
  var specialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`´~¹²³]+/
  var numbers = /[0123456789]+/

  if (specialChars.test(stringToCheck) || numbers.test(stringToCheck)) {
    return true
  } else {
    return false
  }
}

/* 
    Referencia: 

      Função para transformar data URI em Blob

      https://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata/5100158
    
*/

export const dataToBlob = image => {
  var byteString = window.atob(image.split(",")[1])

  var ab = new ArrayBuffer(byteString.length)

  var ia = new Uint8Array(ab)

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ab], { type: "image/jpeg" })
}
