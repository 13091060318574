import { Grid, LinearProgress, Typography, Tooltip } from "@material-ui/core"

import { makeStyles, useTheme } from "@material-ui/core/styles"

import MainButton from "./MainButton"
import { showFormatedCpf, calculateProgressBar } from "../helpers/format"

export default function VacancyInProgressCard({
  status,
  vacancyId,
  vacancyNumber,
  vacancy,
  sentDocuments,
  totalDocuments,
  attachDocuments,
}) {
  const theme = useTheme()

  const borderColor = () => {
    if (status !== "Cancelada") {
      return theme.palette.primary.main
    } else {
      return theme.palette.secondary.main
    }
  }

  const vacancyStatus = () => {
    if (status !== "Cancelada") {
      return "#000"
    } else {
      return theme.palette.secondary.main
    }
  }

  const jobAndPersonIconColor = () => {
    if (status !== "Cancelada") {
      return "primary"
    } else {
      return "secondary"
    }
  }

  const useStyles = makeStyles({
    card: {
      height: "auto",
      padding: 12,
      borderRadius: 8,
      borderColor: borderColor(),
      opacity: vacancy.status === "Em andamento" ? 0.4 : 1,
      background: "#fff",
      color: "#1D3054",
    },
    job: {
      fontWeight: 700,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    vacancyNumber: {
      fontWeight: 700,
    },
    spanData: {
      color: "#4D4D4D",
      fontWeight: 400,
    },
    docsStatus: {
      fontWeight: 700,
      marginLeft: "auto",
    },
    status: {
      fontWeight: 700,
      color: vacancyStatus(),
    },
    buttonsGrid: {
      marginTop: 12,
    },
    divider: {
      height: 2,
      width: "100%",
      marginTop: 20,
      marginBottom: 8,
      background: theme.palette.primary.light,
    },
  })

  const classes = useStyles()

  return (
    <>
      <Grid container className={classes.card}>
        <Grid container style={{ marginBottom: 8 }} alignItems='center'>

          <Grid item xs={8} sm={8} md={8}>
            <Tooltip title={vacancy.nome} placement='top' arrow enterTouchDelay={0}>
              <Typography variant='body1' color={jobAndPersonIconColor()} className={classes.job}>
                {vacancy.nome}
              </Typography>
            </Tooltip>
          </Grid>

          <Typography
            variant='body2'
            className={classes.docsStatus}
            color={jobAndPersonIconColor()}
          >
            {sentDocuments}/{totalDocuments} docs
          </Typography>
        </Grid>

        <Grid container style={{ marginBottom: 8 }}>
          <LinearProgress
            color='primary'
            variant='determinate'
            style={{ width: "100%", borderRadius: 8, height: 8 }}
            value={calculateProgressBar(sentDocuments, totalDocuments)}
          />
        </Grid>

        <Grid container style={{ marginBottom: 8 }}>
          <Typography variant='caption' className={classes.vacancyNumber}>
            Nº de chamado: <span className={classes.spanData}><strong>{vacancy.nrChamado}</strong></span>
          </Typography>
        </Grid>

        {vacancy.dataAbertura && vacancy.dataAbertura !== "01/01/0001" && (
          <Grid container style={{ marginBottom: 8 }}>
            <Typography variant='caption' className={classes.vacancyNumber}>
              Data de abertura: <span className={classes.spanData}><strong>{vacancy.dataAbertura}</strong></span>
            </Typography>
          </Grid>
        )}

        <Grid container style={{ marginBottom: 8 }}>
          <Typography variant='caption' className={classes.vacancyNumber}>
            Empregado: <span className={classes.spanData}><strong>{vacancy.empregado.nome}</strong></span>
          </Typography>
        </Grid>

        <Grid container style={{ marginBottom: 8 }}>
          <Typography variant='caption' className={classes.vacancyNumber}>
            CPF: <span className={classes.spanData}></span><strong>{showFormatedCpf(vacancy?.empregado.cpf)}</strong>
          </Typography>
        </Grid>

        <Grid container justifyContent='space-between' className={classes.buttonsGrid}>
          <MainButton
            style={{ margin: "auto", width: "100%", padding: 16, marginBottom: 8, background: "#fff", color: "#1D3054", border: "1px solid #1D3054", fontWeight: 700, fontSize: 16 }}
            onClick={attachDocuments}>
            Anexar documentos
          </MainButton>

        </Grid>
      </Grid>
    </>
  )
}
