import { makeStyles } from "@material-ui/core/styles"

import { CircularProgress } from "@material-ui/core"

import LinkMobileContainer from "./LinkMobileContainer"
import LogoHeader from "./LogoHeader"


export default function LoadingInterface() {

  const useStyles = makeStyles(theme => ({

    content: {
      height: 600,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: 24
    }

  }))

  const classes = useStyles()

  return (
    <LinkMobileContainer>
      <LogoHeader />
      <div className={classes.content}>
        <CircularProgress />
      </div>
    </LinkMobileContainer>
  )
}