import React from "react";
import { Grid, Button } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom"


export default function VacanciesMenuButton({ activePage }) {
  const theme = useTheme();

  const useStyles = makeStyles({
    button: {
      border: "none",
      borderRadius: 0,
      padding: "10px 0px",
      flex: 1,
      fontWeight: 500,
      width: "100%",
      fontSize: "14px",
      color: theme.palette.text.primary,
      backgroundColor: "transparent",
      transition: "border-color 0.3s, font-weight 0.3s",
    },
    activeButton: {
      borderBottom: "5px solid",
      fontWeight: 700,
      borderBottomColor: theme.palette.primary.main,
    },
    container: {
      display: "flex",
    },
    buttonContainer: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
  });

  
  const classes = useStyles();

  const history = useHistory()

  const handleNavigate = (page) => {
    if (activePage !== page) {
      history.push(`/auth/${page}`);
    }
  };

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item className={classes.buttonContainer}>
        <Button
          className={`${classes.button} ${activePage === "vagas-direcionadas" ? classes.activeButton : ""
            }`}
          onClick={() => handleNavigate("vagas-direcionadas")}
        >
          Vagas Direcionadas
        </Button>
      </Grid>
      <Grid item className={classes.buttonContainer}>
        <Button
          className={`${classes.button} ${activePage === "vagas-em-andamento" ? classes.activeButton : ""
            }`}
          onClick={() => handleNavigate("vagas-em-andamento")}
        >
          Vagas em Andamento
        </Button>
      </Grid>
    </Grid>
  );
}
