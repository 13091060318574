import { useState } from "react"

import { makeStyles } from "@material-ui/core/styles"

import { Typography, Radio } from "@material-ui/core";

import {
  MailOutline,
  Smartphone
} from "@material-ui/icons"

import LinkMobileContainer from "../../../components/LinkMobileContainer"
import LogoHeader from "../../../components/LogoHeader"
import ConfirmButton from "../../../components/ConfirmButton"
import GoBack from "../../../components/GoBack"
import CheckEmptyUser from "./CheckEmptyUser"
import { useHistory } from "react-router-dom"
import useDocumentLink from "../../../hooks/useDocumentLink"
import { vacancyLinkLogin } from "../../../services/api"
import LoadingInterface from "../../../components/LoadingInterface"

export default function ValidationStart() {
  const { setTokenSource, user, setSession } = useDocumentLink()
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const useStyles = makeStyles(theme => ({


    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
      margin: 24
    },

    title: {
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 700,
      color: '#4d4d4d',
      marginBottom: 8
    },

    text: {
      fontSize: "16px",
      lineHeight: "24px",
      color: "#4d4d4d",
      marginBottom: 40
    },

    whereToValidate: {
      fontSize: "16px",
      lineHeight: "24px",
      color: "#4d4d4d",
      fontWeight: 600,
      marginBottom: 16
    },

    options: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItem: 'center',
      marginBottom: 40
    },

    spacer: {
      width: 16
    },

    separator: {
      height: 2,
      backgroundColor: '#b1b3b3',
      marginTop: 40,
      marginBottom: 40
    }

  }))

  const classes = useStyles()

  const handleSubmit = async (event) => {
    event.preventDefault()

    setTokenSource(selected === 'email' ? 'email' : 'sms')
    setLoading(true)
    const session = await vacancyLinkLogin(
      sessionStorage.getItem('url-link-key'),
      selected === "email" ? user.email : user.telefone
    )
    setLoading(false)
    setSession(session)
    history.push('/link/token')
  }
  const [selected, setSelected] = useState('')

  if (loading) return (<LoadingInterface />)

  return (
    <form onSubmit={handleSubmit}>
      <CheckEmptyUser />
      <LinkMobileContainer>
        <LogoHeader />

        <div className={classes.content}>

          <Typography variant='h6' className={classes.title}>
            Validar acesso
          </Typography>
          <Typography variant='body2' className={classes.text}>
            Para ajudar a proteger seus dados, é necessário confirmar sua identidade para finalizar o acesso.
          </Typography>
          <Typography variant='body2' className={classes.whereToValidate}>
            Selecione onde você deseja receber o código de validação
          </Typography>

          <div className={classes.options}>
            <Option
              value={{ id: 'email', label: 'E-mail' }}
              selected={selected === 'email'}
              onSelected={(id) => { setSelected(id) }}
            />
            <div className={classes.spacer} />
            <Option
              value={{ id: 'celular', label: 'Celular' }}
              selected={selected === 'celular'}
              onSelected={(id) => { setSelected(id) }}
            />
          </div>

          <ConfirmButton disabled={selected === ''}>
            CONFIRMAR
          </ConfirmButton>

          <div className={classes.separator} />
          <GoBack />
        </div>
      </LinkMobileContainer>
    </form>
  )
}
const Option = ({ value, selected, onSelected }) => {

  const borderColor = selected ? '#1d3054' : '#848484'
  const textColor = selected ? '#1d3054' : '#4d4d4d'
  const backgroundColor = selected ? '#bfd7ff' : '#ffffff'

  const useStyles = makeStyles(theme => ({

    option: {
      flex: 1,
      height: 56,
      border: "1px solid " + borderColor,
      borderRadius: 4,
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: backgroundColor,
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer'
      }
    },

    label: {
      fontSize: "14px",
      lineHeight: "20px",
      color: textColor,
      fontWeight: 600,
      marginRight: 8
    }

  }))

  const classes = useStyles()
  return (
    <div
      className={classes.option}
      onClick={() => {
        onSelected(value.id);
      }}
      tabIndex={0}
      role="button"
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onSelected(value.id);
        }
      }}
    >
      <Radio
        checked={selected}
        sx={{
          '& .MuiSvgIcon-root': {
            fontSize: 24
          },
        }}
      />
      <Typography variant='h6' className={classes.label}>
        {value.label}
      </Typography>
      {value.id === 'email' ? (
        <MailOutline
          style={{
            color: textColor
          }}
        />
      ) : (
        <Smartphone
          style={{
            color: textColor
          }}
        />
      )}
    </div>
  );
}