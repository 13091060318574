import { makeStyles } from "@material-ui/core/styles"

import { TextField, Typography, InputAdornment } from "@material-ui/core"

import {
  LockOutlined
} from "@material-ui/icons"

import LinkMobileContainer from "../../../components/LinkMobileContainer"
import LogoHeader from "../../../components/LogoHeader"
import ConfirmButton from "../../../components/ConfirmButton"
import useDocumentLink from "../../../hooks/useDocumentLink"
import CheckEmptyUser from "./CheckEmptyUser"
import { useHistory } from "react-router-dom"


function AccessData() {
  const { user } = useDocumentLink();
  const history = useHistory();

  const useStyles = makeStyles(theme => ({

    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      marginTop: 24,
      marginBottom: 24,
      marginLeft: 16,
      marginRight: 16,
    },

    welcomeText: {
      color: "#003da5",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "30px"
    },

    welcomeDetail: {
      color: "#4d4d4d",
      fontSize: "16px",
      lineHeight: "24px",
      marginTop: 8,
      paddingBottom: 8,
      borderBottom: "1px solid #B1B3B3"
    },

    accessText: {
      color: "#4d4d4d",
      fontWeight: 700,
      marginTop: 24,
      marginBottom: 8,
      fontSize: "20px",
      lineHeight: "30px"
    },

    accessDetail: {
      color: "#4d4d4d",
      fontSize: "16px",
      lineHeight: "24px",
      marginBottom: 24
    },

    label: {
      color: "#4d4d4d",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "24px",
      marginBottom: 6.5
    },

    userInput: {
      borderRadius: 4,
      marginBottom: 16,
      background: "#f6f6f6",
      "& .MuiOutlinedInput-input": {
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16
      }
    },

    forgotPassword: {
      color: "#FFF",
      cursor: "pointer",
    },
    TurnstileGrid: {
      justifyContent: "center",
      marginLeft: "auto",
      marginRight: "auto"
    }
  }))

  const classes = useStyles()

  const handleSubmit = async (event) => {
    event.preventDefault()
    history.push('/link/validar')
  }

  return (
    <form onSubmit={handleSubmit}>
      <CheckEmptyUser />
      <LinkMobileContainer>
        <LogoHeader />

        <div className={classes.content}>
          <Typography variant='h6' className={classes.welcomeText}>
            {user.nome}, é você?
          </Typography>
          <Typography variant='body2' className={classes.welcomeDetail}>
            Esse é o link de envio de documentos do grupo Direcional. Se encontrou algum erro entre em contato com o RH.
          </Typography>

          <Typography variant='h6' className={classes.accessText}>
            Dados de acesso:
          </Typography>
          <Typography variant='body2' className={classes.accessDetail}>
            Verifique se os dados abaixo correspondem aos seus dados antes de avançar.
          </Typography>


          <Typography variant='h6' className={classes.label}>
            Telefone
          </Typography>
          <TextField
            fullWidth
            value={`+55 ${user.telefone.substring(0, 2)} ${user.telefone.substring(2)}`}
            variant='outlined'
            autoComplete='username'
            className={classes.userInput}
            disabled
            InputProps={
              {
                endAdornment: (
                  <InputAdornment position='end'>
                    <LockOutlined />
                  </InputAdornment>
                ),

                style: {
                  fontWeight: 400
                },
              }
            }
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#4d4d4d",
              },
            }}
          />

          <Typography variant='h6' className={classes.label}>
            Email
          </Typography>
          <TextField
            fullWidth
            value={user.email}
            variant='outlined'
            className={classes.userInput}
            autoComplete='current-password'
            disabled
            InputProps={{

              style: {
                fontWeight: 400,
              },

              endAdornment: (
                <InputAdornment position='end'>
                  <LockOutlined />
                </InputAdornment>
              ),

            }}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#4d4d4d",
              },
            }}
          />
          <ConfirmButton>
            ACESSAR
          </ConfirmButton>

        </div>
      </LinkMobileContainer>
    </form>
  )
}

export default AccessData
