import { makeStyles, useTheme } from "@material-ui/core/styles"

import { Grid, Typography } from "@material-ui/core"
import MainButton from "./MainButton"

export default function VacanciesTypeButton({
  filled,
  onClick,
  disabled,
  firstIcon,
  buttonText,
  helperText,
  marginBottom,
  numberOfVacancies,
}) {
  const theme = useTheme()

  const background = () => {
      return "#fff"
  }

  const borderColor = () => {
      return "#848484"
  }

  const buttonTextColor = () => {
    if (filled) {
      return "#4D4D4D"
    } else {
      return theme.palette.primary.main
    }
  }

  const useStyles = makeStyles({
    vacanciesTypeButton: {
      height: "auto",
      width: "100%",
      borderRadius: 4,
      padding: 16,
      alignItems: "center",
      background: background(),
      borderColor: borderColor(),
      opacity: disabled ? 0.4 : 1,
      paddingLeft: firstIcon ? 12 : 0,
      gap: 16,
    },
    buttonText: {
      fontSize: 20,
      fontWeight: 700,
      color: buttonTextColor(),
    },
    helperText: {
      fontSize: 11,
      color: "#000",
      fontWeight: 700,
      marginBottom: marginBottom,
    },
    numberOfVacancies: {
      fontWeight: 400,
      marginLeft: "auto",
      color: "#4D4D4D",
      fontSize: 14,
    },
    numberVacancies: {
      color: "#003DA5",
      fontWeight: 700,
    },
  })

  const classes = useStyles()

  const handleClick = () => {
    if (disabled) {
      return
    } else {
      return onClick
    }
  }

  return (
    <>
      <Grid container className={classes.vacanciesTypeButton}>
        <Grid item>{firstIcon}</Grid>

        <Grid item>
          <Typography variant='body2' className={classes.buttonText}>
            {buttonText}
          </Typography>

          <Typography variant='body2' className={classes.numberOfVacancies}>
            <span className={classes.numberVacancies}>{numberOfVacancies} vagas </span>
            disponíveis para assumir
          </Typography>
        </Grid>

        <Grid container alignItems='center'>
          <MainButton
            noPadding
            onClick={handleClick()}
            style={{ margin: "auto", width: "100%", padding: 16 }}
          >
            ACESSAR
          </MainButton>
        </Grid>
      </Grid>
      {helperText ? <Typography className={classes.helperText}>{helperText}</Typography> : null}
    </>
  )
}
