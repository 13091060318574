import { Container, useMediaQuery } from "@material-ui/core"

import { makeStyles } from "@material-ui/core/styles"

export default function LinkMobileContainer({
  children,
}) {

  const smallMobile = useMediaQuery("(max-height:719px)")

  const useStyles = makeStyles(() => ({
    container: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: 'white',
      paddingLeft: 0,
      paddingRight: 0,
      borderRadius: smallMobile ? 0 : 8,
      marginTop: smallMobile ? 0 : 48,
      boxShadow: "0px 2px 4px -2px rgba(77, 77, 77, 0.06), 0px 4px 8px -2px rgba(77, 77, 77, 0.10)"
    },
  }))

  const classes = useStyles()
  return (
    <Container maxWidth='xs' className={classes.container}>
      {children}
    </Container>
  )
}
