
import useAlert from "../hooks/useAlert"
import { DeleteDocument } from "../services/api"
import { swalAlert } from "../helpers/swalAlert"
import useIsLinkRoute from "../hooks/useIsLinkRoute"
import { useHistory } from "react-router-dom"
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core"
import { FiAlertCircle } from "react-icons/fi"
import MainButton from "./MainButton"
import BackdropLoading from "./BackdropLoading"


export default function RemoveDocumentLink({
  open,
  close,
  document,
  getDocuments,
  setDocumentRemoved
}) {
  const history = useHistory()
  const { alert, setAlert } = useAlert()

  const useStyles = makeStyles(theme => ({
    modal: {
      right: 0,
      top: "20%",
      left: "50%",
      zIndex: 99,
      width: "80%",
      maxWidth: 600,
      position: "fixed",
      height: open ? 400 : 0,
      maxHeight: 600,
      borderRadius: 32,
      transition: "height 0.1s",
      transform: "translateX(-50%)",
      display: open ? "flex" : "none",
    },
    modalTitle: {
      fontWeight: 700,
      fontSize: 18,
      color: "#B91C1C",
    },
    buttonsContainer2: {
      padding: 16,
      width: "100%",
      maxWidth: 600,
    },
  }))

  const classes = useStyles()

  const isLinkRoute = useIsLinkRoute();

  const deleteDocument = async () => {
    if (document?.statusDoUsuario === "Documento Pendente" && document?.justificativa !== null) {
      const targetRoute = isLinkRoute ? '/link/documentos/enviar' : '/auth/documentos/enviar';
      history.push(targetRoute);
    } else {
      setTimeout(close, 0);
      setAlert({ ...alert, waitingResponse: true });
      let response = await DeleteDocument(document?.id);
      const targetDocumentsRoute = isLinkRoute ? '/link/documentos/' : '/auth/documentos/';

      if (response?.status >= 200 && response?.status < 300) {
        swalAlert(
          "success",
          `<strong>Documento deletado com sucesso</strong><br />O documento <strong>${document?.nome}</strong> foi deletado.`,
          "FECHAR"
        );
        setDocumentRemoved(true);
        await getDocuments();
        history.push(targetDocumentsRoute);
      } else {
        swalAlert(
          "error",
          `Não foi possível remover o documento ${document?.nome}`,
          "TENTAR NOVAMENTE"
        );
      }
    }

    setAlert({ ...alert, waitingResponse: false });
  }


  return (
    <>
      <Paper elevation={20} className={classes.modal}>
        <Grid container style={{
          padding: 16,
          justifyContent: "center"
        }}>
          <FiAlertCircle
            size={48}
            color='#B91C1C'
            style={{
              marginBottom: 8,
            }}
          />
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant='h6' color='primary' className={classes.modalTitle} align='center'>
              {document?.statusDoUsuario === "Documento Pendente" &&
                document?.justificativa !== null
                ? "Deseja reenviar este documento?"
                : "Deseja deletar este documento?"}
            </Typography>
          </Grid>

          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            alignItems='center'
            style={{
              marginTop: 8,
              marginBottom: 8,
              width: "100%",
              justifyContent: "center",

            }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
              style={{
                textAlign: "center",
              }}>
              <Typography
                variant='caption'
                align='center'
                fontSize={14}
              >
                A ação não pode ser desfeita depois, ela irá <strong>remover o documento</strong> que você adicionou a vaga.
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            direction='column'
            alignItems='center'
            className={classes.buttonsContainer2}
          >
            <MainButton
              size='medium'
              fullWidth={true}
              buttonStyle='contained'
              onClick={deleteDocument}
              style={{
                marginBottom: 8,
                maxWidth: 400,
                textTransform: "uppercase",
                backgroundColor: "#B91C1C",
                color: "#FFF",
                border: "2px solid #B91C1C",
                borderRadius: 8,
                padding: 8,
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              Deletar documento
            </MainButton>

            <MainButton
              size='medium'
              onClick={close}
              fullWidth={true}
              buttonStyle='error'
              style={{
                maxWidth: 400,
                textTransform: "uppercase",
                color: "#1D3054",
                border: "2px solid #1D3054",
                backgroundColor: "#FFF",
                borderRadius: 8,
                padding: 8,
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              Cancelar
            </MainButton>

          </Grid>
        </Grid>
      </Paper>

      <BackdropLoading open={alert?.waitingResponse} />
    </>
  )
}
