import { useEffect } from "react"

import { Route, Switch, Redirect, useLocation } from "react-router-dom"

import { ThemeProvider, createTheme } from "@material-ui/core/styles"

import ReactGA from "react-ga4"

import AuthProvider from "./context/AuthContext"
import TabsProvider from "./context/TabsContext"
import { isAuthenticated } from "./services/auth"
import Login from "./pages/UserRecognition/Login"
import AlertProvider from "./context/AlertContext"
import Welcome from "./pages/Authenticated/Welcome"
import RouteNotFound from "./components/RouteNotFound"
import Vacancies from "./pages/Authenticated/Vacancies"
import VacanciesProvider from "./context/VacanciesContext"
import CostCentersProvider from "./context/CostCentersContext"
import DocumentsList from "./pages/Authenticated/DocumentsList"
import RemoveVacancy from "./pages/Authenticated/RemoveVacancy"
import VacancyDetails from "./pages/Authenticated/VacancyDetails"
import UploadDocument from "./pages/Authenticated/UploadDocument"
import VerificationCode from "./pages/UserRecognition/VerificationCode"
import VacanciesInProgress from "./pages/Authenticated/VacanciesInProgress"
import DocumentAttach from "./pages/Authenticated/DocumentAttach"
import AccessData from "./pages/DocumentLink/Access/AccessData"
import ValidationStart from "./pages/DocumentLink/Access/ValidationStart"
import ValidateToken from "./pages/DocumentLink/Access/ValidateToken"
import LoadingLink from "./pages/DocumentLink/Access/LoadingLink"
import WelcomeLink from "./pages/DocumentLink/WelcomeLink"
import DocumentLinkProvider from "./context/DocumentLinkContext"
import HomeLink from "./pages/DocumentLink/HomeLink"
import FichaProvider from "./context/FichaContext"
import FichaCadastral from "./pages/DocumentLink/FichaCadastral/FichaCadastral"
import Preenchimento from "./pages/DocumentLink/FichaCadastral/Preenchimento"

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
)

function usePageViews() {
  let location = useLocation()

  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize("G-7RN6X13Z8P")
      window.GA_INITIALIZED = true
    }

    ReactGA.send({ hitType: "pageview", page: location.pathname })
  }, [location])
}

export default function App() {
  const theme = createTheme({
    background: {
      default: "#FFF",
    },
    palette: {
      primary: {
        main: "#1C2F54",
        light: "#848484",
      },
      secondary: {
        main: "#AB3237",
      },
      error: {
        main: "#D00",
      },
    },
  })

  const privateRoutes = [
    { component: Welcome, path: "/auth", exact: true },
    { component: RemoveVacancy, path: "/auth/vaga/excluir" },
    { component: VacancyDetails, path: "/auth/detalhes-vaga" },
    { component: Vacancies, path: "/auth/vagas-direcionadas" },
    { component: UploadDocument, path: "/auth/documentos/enviar", exact: true },
    { component: DocumentsList, path: "/auth/documentos", exact: true },
    { component: DocumentAttach, path: "/auth/documentos/anexar", exact: true },
    { component: VacanciesInProgress, path: "/auth/vagas-em-andamento" },
    { component: HomeLink, path: "/auth/home", exact: true },
    { component: FichaCadastral, path: "/auth/ficha-cadastral", exact: true },
    {
      component: (props) => (
        <Preenchimento
          {...props}
          onSave={(componentName, data) => { }}
          onExit={(componentName, data) => { }}
        />
      ),
      path: "/auth/ficha-cadastral/preenchimento",
      exact: true,
    },
    { component: WelcomeLink, path: "/link/bem-vindo", exact: true },
    { component: DocumentAttach, path: "/link/documentos/anexar", exact: true },
    { component: DocumentsList, path: "/link/documentos", exact: true },
    { component: UploadDocument, path: "/link/documentos/enviar", exact: true },
    { component: HomeLink, path: "/link/home", exact: true },
    { component: FichaCadastral, path: "/link/ficha-cadastral", exact: true },
    {
      component: (props) => (
        <Preenchimento
          {...props}
          onSave={(componentName, data) => { }}
          onExit={(componentName, data) => { }}
        />
      ),
      path: "/link/ficha-cadastral/preenchimento",
      exact: true,
    },
  ];
  usePageViews()

  return (
    <AuthProvider>
      <CostCentersProvider>
        <VacanciesProvider>
          <TabsProvider>
            <DocumentLinkProvider>
              <FichaProvider>
                <AlertProvider>
                  <ThemeProvider theme={theme}>
                    <Switch>
                      <Route exact path="/" component={Login} />

                      <Route exact path="/verificar-codigo" component={VerificationCode} />

                      {privateRoutes.map(route => (
                        <PrivateRoute
                          key={route.path}
                          path={route.path}
                          exact={route.exact}
                          component={route.component}
                          onSave={route.onSave}
                          onExit={route.onExit}
                        />
                      ))}
                      <Route path="/link/carregar" component={LoadingLink} />
                      <Route path="/link/dados-acesso" component={AccessData} />
                      <Route path="/link/validar" component={ValidationStart} />
                      <Route path="/link/token" component={ValidateToken} />
                      <Route component={RouteNotFound} />
                    </Switch>
                  </ThemeProvider>
                </AlertProvider>
              </FichaProvider>
            </DocumentLinkProvider>
          </TabsProvider>
        </VacanciesProvider>
      </CostCentersProvider>
    </AuthProvider>
  )
}
