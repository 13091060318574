import { makeStyles } from "@material-ui/core/styles"

import { Grid, Typography } from "@material-ui/core"

import { contactWhatsApp } from "../helpers/device"

export default function CallCenter({ marginTop, marginBottom, whiteLink }) {
  const useStyles = makeStyles(theme => ({
    container: {
      marginTop: marginTop ? marginTop : 0,
      marginBottom: marginBottom ? marginBottom : 0,
    },
    text: {
      textDecoration: "underline",
      color: whiteLink ? "#FFF" : "#551A8B",
    },
  }))

  const classes = useStyles()

  return (
    <>
      <Grid
        container
        justifyContent='center'
        className={classes.container}
        onClick={() => contactWhatsApp("")}
      >
        <Typography variant='subtitle1' className={classes.text}>
          Central de atendimento
        </Typography>
      </Grid>
    </>
  )
}
