import React, { useContext, useState, useEffect } from 'react';
import { FichaContext } from '../../../../context/FichaContext';
import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Tooltip, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

const GeneroOrientacao = ({ onSave, onExit, setLoading, isLoading }) => {
  const fichaContext = useContext(FichaContext);
  const [formData, setFormData] = useState({
    identidadeGenero: fichaContext?.ficha.identidadeGenero != null ? String(fichaContext.ficha.identidadeGenero) : null,
    orientacaoSexual: fichaContext?.ficha.orientacaoSexual != null ? String(fichaContext.ficha.orientacaoSexual) : null,
    sexoColaborador: fichaContext?.ficha.sexoColaborador || null,
    numeroCertificadoReservista: fichaContext?.ficha.numeroCertificadoReservista || '',
    situacaoMilitar: fichaContext?.ficha.situacaoMilitar || '',
  });

  useEffect(() => {
    setFormData({
      identidadeGenero: fichaContext?.ficha.identidadeGenero != null ? String(fichaContext.ficha.identidadeGenero) : null,
      orientacaoSexual: fichaContext?.ficha.orientacaoSexual != null ? String(fichaContext.ficha.orientacaoSexual) : null,
      sexoColaborador: fichaContext?.ficha.sexoColaborador || null,
    });
  }, [fichaContext]);



  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (formData.sexoColaborador === 'F') {
      setFormData((prevState) => ({
        ...prevState,
        numeroCertificadoReservista: '',
        situacaoMilitar: '',
      }));
    }
  }, [formData.sexoColaborador]);

  useEffect(() => {
    const isValid = formData.identidadeGenero && formData.orientacaoSexual && formData.sexoColaborador;
    setIsFormValid(Boolean(isValid));
  }, [formData.identidadeGenero, formData.orientacaoSexual, formData.sexoColaborador]);

  if (!fichaContext) {
    return null;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = () => {
    const dataToSave = {
      identidadeGenero: formData.identidadeGenero,
      orientacaoSexual: formData.orientacaoSexual,
      sexoColaborador: formData.sexoColaborador,
      numeroCertificadoReservista: formData.numeroCertificadoReservista || undefined,
      situacaoMilitar: formData.situacaoMilitar || undefined,
    };

    onSave('generoOrientacao', dataToSave);
  };

  const handleExit = () => {
    const dataToSave = {
      identidadeGenero: formData.identidadeGenero,
      orientacaoSexual: formData.orientacaoSexual,
      sexoColaborador: formData.sexoColaborador,
      numeroCertificadoReservista: formData.numeroCertificadoReservista || undefined,
      situacaoMilitar: formData.situacaoMilitar || undefined,
    };

    onExit('onExit', dataToSave);
  };

  return (
    <>
      <Grid style={{ marginRight: 16, marginLeft: 16, marginBottom: 16 }}>
        <Typography variant="h6" className='titleComponente'>
          Gênero e orientação Sexual
        </Typography>
        <Typography variant="body2" className='descriptionComponente'>
          Etapa obrigatória <span className='asterisk'>*</span>
        </Typography>
      </Grid>
      <form className='formComponente'>

        <Grid className='gridComponente'>
          <Typography variant="h6" className='titleTypography'>
            Sexo <span className='asterisk'>*</span>
          </Typography>
          <FormControl className='formControl' component="fieldset" required>
            <RadioGroup name="sexoColaborador" value={formData.sexoColaborador} onChange={handleChange}>
              <FormControlLabel value='F' control={<Radio />} label="Feminino" />
              <FormControlLabel value='M' control={<Radio />} label="Masculino" />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid className='gridComponente'>
          <Typography variant="h6" className='titleTypography'>
            Identidade de Gênero <span className='asterisk'>*</span>
          </Typography>
          <FormControl className='formControl' component="fieldset" required>
            <RadioGroup name="identidadeGenero" value={formData.identidadeGenero} onChange={handleChange}>
              <div className='optionTooltip'><FormControlLabel value="1" control={<Radio />} label="Cisgênero" /><Tooltip title="Pessoas que se identificam com o gênero que foi determinado pelo nascimento" arrow>
                <InfoOutlined className='tooltipOutlined' />
              </Tooltip></div>
              <div className='optionTooltip'><FormControlLabel value="2" control={<Radio />} label="Transgênero" /><Tooltip title="Pessoas que não se identificam com a identidade de gênero atribuídas ao nascimento" arrow>
                <InfoOutlined className='tooltipOutlined' />
              </Tooltip></div>
              <div className='optionTooltip'><FormControlLabel value="3" control={<Radio />} label="Não-binário" /><Tooltip title="Pessoas que não se identificam com as identidades de homem ou mulher" arrow>
                <InfoOutlined className='tooltipOutlined' />
              </Tooltip></div>
              <div className='optionTooltip'><FormControlLabel value="5" control={<Radio />} label="Outros" /><Tooltip title="Opção para indivíduos com identidades de gênero que não se enquadram nas categorias anteriores." arrow>
                <InfoOutlined className='tooltipOutlined' />
              </Tooltip></div>
              <FormControlLabel value="4" control={<Radio />} label="Não desejo informar" />
            </RadioGroup>

          </FormControl>
        </Grid>

        <Grid className='gridComponente'>
          <Typography variant="h6" className='titleTypography'>
            Orientação Sexual <span className='asterisk'>*</span>
          </Typography>
          <FormControl className='formControl' component="fieldset" required>
            <RadioGroup name="orientacaoSexual" value={formData.orientacaoSexual} onChange={handleChange}>
              <div className='optionTooltip'><FormControlLabel value="1" control={<Radio />} label="Assexual" /><Tooltip title="Nenhuma atração sexual" arrow>
                <InfoOutlined className='tooltipOutlined' />
              </Tooltip></div>
              <div className='optionTooltip'><FormControlLabel value="2" control={<Radio />} label="Bissexual" /><Tooltip title="Atração afetiva ou sexual por mais de um gênero" arrow>
                <InfoOutlined className='tooltipOutlined' />
              </Tooltip></div>
              <div className='optionTooltip'><FormControlLabel value="3" control={<Radio />} label="Heterossexual" /><Tooltip title="Atração afetiva ou sexual pelo gênero oposto" arrow>
                <InfoOutlined className='tooltipOutlined' />
              </Tooltip></div>
              <div className='optionTooltip'><FormControlLabel value="4" control={<Radio />} label="Homossexual" /><Tooltip title="Atração afetiva ou sexual pelo mesmo gênero" arrow>
                <InfoOutlined className='tooltipOutlined' />
              </Tooltip></div>
              <div className='optionTooltip'><FormControlLabel value="5" control={<Radio />} label="Pansexual" /><Tooltip title="Atração afetiva ou sexual por todos os gêneros" arrow>
                <InfoOutlined className='tooltipOutlined' />
              </Tooltip></div>
              <div className='optionTooltip'><FormControlLabel value="7" control={<Radio />} label="Outros" /><Tooltip title="Opção para indivíduos com Orientação Sexual que não se enquadram nas categorias anteriores." arrow>
                <InfoOutlined className='tooltipOutlined' />
              </Tooltip></div>
              <FormControlLabel value="6" control={<Radio />} label="Não desejo informar" />
            </RadioGroup>

          </FormControl>
        </Grid>

      </form>
      <div className='divButtons'>
        <Button
          className='buttonSaveNext'
          variant="contained"
          onClick={handleSave}
          disabled={!isFormValid || isLoading}>
          Próxima Etapa
        </Button>
        <Button
          className='buttonSaveExit'
          variant="contained"
          onClick={handleExit}
          disabled={!isFormValid || isLoading}>
          Salvar e Continuar Depois
        </Button>
      </div>
    </>
  );
};

export default GeneroOrientacao;
