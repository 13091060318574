import { useEffect, useState } from "react"

import { useHistory, useLocation } from "react-router-dom"

import { Grid, Typography, Box } from "@material-ui/core"

import { makeStyles } from "@material-ui/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import Swal from "sweetalert2"

import "./pdfStyle.css"
import useAuth from "../../hooks/useAuth"
import useTabs from "../../hooks/useTabs"
import useModal from "../../hooks/useModal"
import useAlert from "../../hooks/useAlert"
import { swalAlert } from "../../helpers/swalAlert"
import { AccessDevice } from "../../helpers/device"
import { UploadDocument } from "../../services/api"
import MainButton from "../../components/MainButton"
import BackdropLoading from "../../components/BackdropLoading"
import DevicePermission from "../../components/DevicePermission"
import { MdDelete, MdPictureAsPdf } from "react-icons/md"
import ReportOutlined from "@material-ui/icons/ReportOutlined"


export default function Steps({ nextTab, lastTab, multiplePages }) {
  const smallMobile = useMediaQuery("(max-height:600px)")

  const bigScreenDevice = useMediaQuery("(min-width:600px)")

  const useStyles = makeStyles((theme) => ({
    imageContainer: {
      padding: 8,
      maxWidth: 444,
      marginTop: 16,
      maxHeight: window.innerHeight / 2,
    },
    image: {
      maxWidth: 380,
      maxHeight: 280,
      width: window.innerWidth / 1.2,
      height: window.innerHeight / 2.0,
    },
    text: {
      color: "#FFF",
      lineHeight: 1,
      textAlign: "center",
      marginTop: !smallMobile ? 16 : 8,
      fontSize: 14,
    },
    documentName: {
      color: "#FFF",
      fontWeight: 700,
      textAlign: "center",
      marginTop: !smallMobile ? 16 : 4,
      fontSize: bigScreenDevice ? 16 : "4vmax",
    },
    cloudIcon: {
      marginTop: !smallMobile ? 32 : 16,
      marginBottom: !smallMobile ? 16 : 8,
      color: theme.palette.primary.light,
      width: bigScreenDevice ? 250 : "16vmax",
      height: bigScreenDevice ? 260 : "20vmax",
    },
    attachButton: {
      marginTop: !smallMobile ? 24 : 12,
    },
    verticalUploadMessage: {
      padding: 0,
      marginTop: 12,
      color: "#B91C1C",
      borderRadius: 4,
      border: "1px solid",
      background: "#FEE2E2",
      borderColor: "#FEE2E2",
    },
    textVerticalUploadMessage: {
      padding: "16px 8px"
    },
  }))

  const classes = useStyles()
  const { modal, setModal } = useModal()
  const { alert, setAlert } = useAlert()
  const { images, setImages } = useAuth()
  const history = useHistory()
  const { setTab } = useTabs()
  const [file, setFile] = useState()
  const [documentToUpload, setDocumentToUpload] = useState([])
  const location = useLocation()

  useEffect(() => {
    setDocumentToUpload(JSON.parse(localStorage.getItem("documentToUpload")))
    if (!localStorage.getItem("permissaoDispositivo"))
      setModal({ ...modal, devicePermission: true })
    if (localStorage.getItem("permissaoDispositivo") === "Não")
      setModal({ ...modal, devicePermission: true })
  }, [])

  const invalidDocument = () => {
    setTimeout(() => {
      setAlert({
        open: false,
        message: "",
        severity: "error",
        waitingResponse: false,
      })
      setFile(undefined)
      setImages({ imagesArray: [] })
      setTab("1")
    }, 4000)
  }

  const handleImages = async () => {
    if (lastTab || multiplePages) {
      setAlert({ ...alert, waitingResponse: true })

      let response = await UploadDocument(images?.imagesArray, documentToUpload?.id)

      if (response?.status >= 200 && response?.status < 300) {
        setAlert({ ...alert, waitingResponse: false })

        swalAlert(
          "success",
          `O documento <strong>${documentToUpload?.nome}</strong> foi anexado!`,
          "OK, ENTENDI"
        )
        if (location.pathname === '/link/documentos/enviar') {
          history.push('/link/documentos');
        } else {
          history.push('/auth/documentos');
        }
      } else if (response.includes("Gateway")) {
        Swal.fire({
          title:
            "<h5 style='color: #A93335; margin-top: 8px; margin-bottom: 0px'>Falha de solicitação</h5>",
          allowOutsideClick: false,
          confirmButtonColor: "#29333C",
          confirmButtonText: "        TENTAR NOVAMENTE        ",
          text: "O servidor encontrou um erro temporário e não pôde concluir sua solicitação, tente novamente.",
        })

        setAlert({
          open: false,
          message: "",
          severity: "error",
          waitingResponse: false,
        })

        return
      } else {
        if (response?.includes("CPF não identificado")) {
          setModal({ ...modal, cpfNotFound: true })
          return
        }

        setAlert({
          open: true,
          message: response,
          severity: "error",
          waitingResponse: false,
        })

        invalidDocument()
      }
    } else {
      setTab(nextTab)
    }
  }

  const checkIfImagesArrayIsEqualToNumberOfDocumentPages = () => {
    if (images?.imagesArray?.length === documentToUpload?.numeroPaginas) {
      return true
    }
  }

  const handleRemoveFile = () => {
    if (images?.imagesArray.length > 0) {
      images.imagesArray.pop();
      if (images.imagesArray.length > 0) {
        setFile(images.imagesArray[images.imagesArray.length - 1]);
      } else {
        setFile(undefined);
      }
      setImages({ imagesArray: [...images.imagesArray] });
    }
  };

  return (
    <>
      {file || images?.imagesArray.length > 0 ? (
        <>
          <Grid container className={classes.imageContainer} justifyContent='center'>
            <Grid container justifyContent='flex-start' style={{ marginTop: 12 }}>
              <Grid item>
                <MdDelete
                  style={{
                    backgroundColor: "#B91C1C",
                    border: "1px solid transparent",
                    color: "#fff",
                    height: 32,
                    width: 32,
                    padding: 2,
                    borderRadius: 100,
                    cursor: "pointer",
                    marginTop: -15,
                    marginLeft: -15,
                    zIndex: 99,
                    position: "absolute",
                  }}
                  onClick={handleRemoveFile}
                />
              </Grid>
            </Grid>
            {typeof file === "string" && file?.includes("application/pdf") ? (
              <MdPictureAsPdf style={{ height: "100%", width: "100%", color: "#FFF" }} />
            ) : (
              <>
                {typeof file === "string" ? (
                  <img
                    alt='File'
                    className={classes.image}
                    src={file}
                  />
                ) : file && (
                  <img
                    alt='File'
                    className={classes.image}
                    src={URL.createObjectURL(file)}
                  />
                )}
              </>
            )}
          </Grid>

          <Typography className={classes.documentName}>
            Sua foto ficou nítida e bem iluminada?
          </Typography>

          <Typography variant='body1' className={classes.text}>
            A imagem precisa mostrar o documento com clareza.
          </Typography>

          <Grid container justifyContent='center' style={{ marginTop: 24 }}>
            {multiplePages && !checkIfImagesArrayIsEqualToNumberOfDocumentPages() && (
              <AccessDevice id='selectFile' setFile={setFile} buttonText='ADICIONAR OUTRA IMAGEM' link={true} />
            )}
            <MainButton
              fullWidth
              size='large'
              style={{
                width: "100%",
                marginTop: 16,
                fontWeight: 700,
                backgroundColor: "transparent",
                border: "2px solid #FFF",
                color: "#FFF",
                height: 54,
                borderRadius: 8,
              }}
              onClick={handleImages}
              buttonStyle='outlinedLight'
            >
              {multiplePages || lastTab ? "SALVAR IMAGEM" : "Avançar"}
            </MainButton>
          </Grid>
        </>
      ) : (
        <>
          <Box className={classes.verticalUploadMessage}>
            <Grid container justifyContent='space-around' alignItems='center' className={classes.textVerticalUploadMessage}>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <ReportOutlined />
              </Grid>

              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <Typography
                  color='#B91C1C'
                  variant='caption'
                  style={{ lineHeight: 0, letterSpacing: 0, fontSize: 14, fontWeight: 400 }}
                >
                  Faça o envio do documento sem outros objetos em tela e na posição{" "}
                  {documentToUpload?.orientacaoPadrao?.toLocaleLowerCase()}.
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Grid container justifyContent='center'>
            <img src='/assets/images/fileUpload2.png' alt='Upload' className={classes.cloudIcon} />
          </Grid>

          <Typography variant={!smallMobile ? "h6" : "body1"} className={classes.text}>
            Você precisa enviar:
          </Typography>

          <Typography className={classes.documentName}>{documentToUpload?.nome}</Typography>

          <Typography variant='body1' className={classes.text}>
            {documentToUpload?.descricao}
          </Typography>

          <Grid container justifyContent='center' className={classes.attachButton}>
            <AccessDevice
              id='selectFile'
              setFile={setFile}
              buttonText='ENVIAR DOCUMENTO'
              link={true}
            />
          </Grid>
        </>
      )}

      <DevicePermission
        open={modal?.devicePermission}
        close={() => setModal({ ...modal, devicePermission: false })}
      />

      <BackdropLoading open={alert.waitingResponse} />
    </>
  )
}
