import { createContext, ReactNode, useState } from "react"

type AlertTypes = {
  alert?: object
  setAlert?: (alert: object) => void
}

type AlertTypesProps = {
  children: ReactNode
}

export const AlertContext = createContext({} as AlertTypes)

const AlertProvider = (props: AlertTypesProps) => {
  const [alert, setAlert] = useState<object>({
    open: false,
    message: "",
    severity: "",
    waitingResponse: false,
  })

  return (
    <AlertContext.Provider
      value={{
        alert,
        setAlert,
      }}
    >
      {props.children}
    </AlertContext.Provider>
  )
}

export default AlertProvider
